import BigNumber from 'bignumber.js';
import { removeNeedlessZeros } from '.';

export const toIntegerFromDecimal = (value: string, places: number) => {
  const newValue = value.replace(',', '.');

  const x = new BigNumber(newValue, 10);
  const power = new BigNumber(10, 10).exponentiatedBy(places);
  const y = x.multipliedBy(power);

  return removeNeedlessZeros(y.toFixed(places));
};
