import * as S from './styled';
import { useMediaQuery } from '../../../../hooks';
import Hamburger from '../Hamburger/Hamburger';
import { Logo } from '../../../../components';

interface IHeader {
  onHandleToggle: () => void;
  onHandleDisconnect: () => void;
}

const Header = ({ onHandleToggle, onHandleDisconnect }: IHeader) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <S.Container>
      <div className="flex items-center">
        <S.HamburgerWrapper type="button" onClick={onHandleToggle} data-testid="toggleFun">
          <Hamburger />
        </S.HamburgerWrapper>
        {isMobile ? null : <Logo />}
      </div>

      <div className="flex items-center">
        <S.Disconnect type="button" onClick={onHandleDisconnect}>
          Disconnnect
        </S.Disconnect>
      </div>
    </S.Container>
  );
};

export default Header;
