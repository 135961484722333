import { AlertAction, AlertActionTypes } from '../../actions/alertActions/alertActions';

type AlertsState = AlertObject[];

export const initialState: AlertsState = [];

export const alertsReducer = (
  state: AlertsState = initialState,
  action: AlertAction,
): AlertsState => {
  switch (action.type) {
    case AlertActionTypes.WARNING_ALERT:
      return [...state, action.payload];
    case AlertActionTypes.SUCCESS_ALERT:
      return [...state, action.payload];
    case AlertActionTypes.ERROR_ALERT:
      return [...state, action.payload];
    case AlertActionTypes.INFO_ALERT:
      return [...state, action.payload];
    case AlertActionTypes.REMOVE_ALERT:
      return [...state].filter((alert) => alert.id !== action.payload);
    default:
      return state;
  }
};
