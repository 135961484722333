import { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import routes from './utils/routes';
import AlertsList from './components/AlertsList/AlertsList';
import BackgroundTemplate from './templates/BackgroundTemplate/BackgroundTemplate';
import Spinner from './components/Spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import { usersActions } from './store';
import { autoConnectEthereumProvider } from './store/actions';

const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));

const App = () => {
  const dispatch = useDispatch();
  const userIsAuth = useSelector((store: RootStore) => store.users.isAuth);
  const walletStatus = useSelector((store: RootStore) => store.wallet.status);
  const location = useLocation();

  useEffect(() => {
    dispatch(usersActions.fetchLoggedUser());
  }, [location.pathname]);

  useEffect(() => {
    if (userIsAuth && walletStatus !== 'CONNECTED') {
      dispatch(autoConnectEthereumProvider());
    }
  }, [userIsAuth]);

  return (
    <>
      <BackgroundTemplate />
      <Suspense fallback={<Spinner />}>
        <AnimatePresence>
          <Switch>
            <Route key="login" path={routes.home} component={LoginPage} exact />
            <PrivateRoute key="dashboard" path={routes.dashboard.self} component={Dashboard} />
            <PrivateRoute admin key="admin" path={routes.admin.self} component={AdminPanel} />
            <Redirect exact from="*" to={routes.dashboard.self} />
          </Switch>
        </AnimatePresence>
      </Suspense>
      <AlertsList />
    </>
  );
};

export default App;
