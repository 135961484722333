import styled, { css } from 'styled-components';
import CheckedIcon from '../../assets/checked.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1s ease-in;
`;

type ICircle = {
  active: boolean;
  checked?: boolean;
};

const Circle = styled.div<ICircle>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight};
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.default)};
  font-size: 12px;
  line-height: 15px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  transition: 0.3s;

  ${({ checked, active }) => {
    switch (checked) {
      case true: {
        return css`
          background-color: ${({ theme }) => theme.colors.buttons.green};
        `;
      }
      default: {
        return css`
          background-color: ${({ theme }) =>
            active ? theme.colors.buttons.green : theme.colors.white};
          color: ${({ theme }) => (active ? theme.colors.white : theme.colors.default)};
        `;
      }
    }
  }}
`;

const Description = styled.p<ICircle>`
  margin-top: 7px;
  width: 80px;
  text-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ active, theme }) => (active ? 700 : theme.typography.fontWeight)};
  color: ${({ active, theme }) => (active ? theme.colors.buttons.green : theme.colors.default)};
  font-size: 12px;
  line-height: 15px;
  transition: 0.1s;
`;

const CheckedIco = styled.img`
  width: 11px;
`;

export type StageIndicatorType = {
  active: boolean;
  stageNo: number;
  stageDescription: string;
  checked?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const StageIdicator = ({
  active,
  stageNo,
  stageDescription,
  checked = false,
  ...args
}: StageIndicatorType) => {
  return (
    <Wrapper {...args}>
      <Circle checked={checked} active={active}>
        {checked ? <CheckedIco src={CheckedIcon} /> : stageNo}
      </Circle>
      <Description active={active}>{stageDescription}</Description>
    </Wrapper>
  );
};

export default StageIdicator;
