import { AxiosError } from 'axios';
import isAxiosError from './isAxiosError';

type ApiError = {
  error: {
    statusCode: number;
    name: string;
    message: string;
  };
};

const isApiError = (error: any): error is AxiosError<ApiError> => {
  if (isAxiosError(error)) {
    const errorData = error.response?.data as any;
    if (errorData && errorData.error && errorData.error.statusCode && errorData.error.message) {
      return true;
    }
  }

  return false;
};

export default isApiError;
