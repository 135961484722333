export const dev = () => {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development') {
    return true;
  }
  return false;
};

const config = {
  API_URL: dev() ? 'https://api.dev3.nextrope.com' : 'https://stake.ecoway.org/api',
  NETWORK_ID: dev() ? 97 : 56,
  INFURA_KEY: dev() ? 'fddbda7409e24752aaf962bebebfffc7' : 'fddbda7409e24752aaf962bebebfffc7',
  TOKEN_ADDRESS: dev()
    ? '0x00721f0ec936da8dd2bf1060b96e82a0244232f5'
    : '0x3a14785035CF80aD95a969Bfc750584a3C16A6E6',
  // STAKING_ADDRESS: '0xdc929d0505680D2805d70d558ced4714B7d2f1DE',
  STAKING_ADDRESS: dev()
    ? '0xc71C734B591155C29EF09F05B52144864fb20c77'
    : '0xD087fF477037898cFb24477192F4Be9e5fE3E53d',
} as const;

export default config;
