import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../utils';
import { Spinner } from '../../components';
import AdminLayout from '../../layout/AdminLayout/AdminLayout';
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const Users = lazy(() => import('./Users/Users'));
const Withdrawals = lazy(() => import('./Withdrawals/Withdrawals'));
const UserDetails = lazy(() => import('./UserDetails/UserDetails'));
const LotteryWinsDetails = lazy(() => import('./LotteryWinsDetails/LotteryWinsDetails'));
const StakingGainsDetails = lazy(() => import('./StakingGainsDetails/StakingGainsDetails'));
const LoginAttempts = lazy(() => import('./LoginAttempts/LoginAttempts'));
const StakingHistory = lazy(() => import('./StakingHistory/StakingHistory'));
const LotteryCumulative = lazy(() => import('./LotteryCumulative/LotteryCumulative'));
const StakingDayDetails = lazy(() => import('./StakingDayDetails/StakingDayDetails'));
const Settings = lazy(() => import('./Settings/Settings'));
const LotteryWins = lazy(() => import('./LotteryWins/LotteryWins'));
const StakingGains = lazy(() => import('./StakingGains/StakingGains'));
const Balance = lazy(() => import('./Balance/Balance'));

const AdminPanel = () => {
  return (
    <AdminLayout>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={routes.admin.self} component={Dashboard} />
          <Route path={routes.admin.users.userDetails} component={UserDetails} />
          <Route path={routes.admin.users.self} component={Users} />
          <Route path={routes.admin.lotteryWins.details} component={LotteryWinsDetails} />
          <Route path={routes.admin.stakingGains.details} component={StakingGainsDetails} />
          <Route path={routes.admin.withdrawals} component={Withdrawals} />
          <Route exact path={routes.admin.stakingHistory.self} component={StakingHistory} />
          <Route exact path={routes.admin.lotteryCumulative} component={LotteryCumulative} />
          <Route
            path={routes.admin.stakingHistory.stakingDayHistory}
            component={StakingDayDetails}
          />
          <Route path={routes.admin.loginAttempts} component={LoginAttempts} />
          <Route path={routes.admin.settings} component={Settings} />
          <Route path={routes.admin.balance} component={Balance} />
          <Route path={routes.admin.lotteryWins.self} component={LotteryWins} />
          <Route path={routes.admin.stakingGains.self} component={StakingGains} />
        </Switch>
      </Suspense>
    </AdminLayout>
  );
};

export default AdminPanel;
