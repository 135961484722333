import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

export type ButtonType = {
  description: string;
  disabled?: boolean;
  bigSize?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
} & React.HTMLAttributes<HTMLButtonElement>;

type ICircle = {
  position: {
    top: string;
    left: string;
  };
};

type IButton = {
  disabled: boolean;
  bigSize: boolean;
};

const ButtonWrapper = styled.button<IButton>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.buttons.grayText : theme.colors.white};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.buttons.gray : theme.colors.buttons.green};

  border: none;

  cursor: ${({ disabled, theme }) => (disabled ? 'initial' : 'pointer')};
  position: relative;
  transition: all 0.3s ease-out;
  z-index: ${({ theme }) => theme.zIndex.centerCard};

  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? null : theme.colors.buttons.hover)};
  }

  ${({ bigSize }) => {
    switch (bigSize) {
      case true: {
        return css`
          padding-top: 17px;
          padding-bottom: 17px;
          width: 100%;
          border-radius: none;
        `;
      }
      default: {
        return css`
          padding: 9px 15px;
          border-radius: 3px;
        `;
      }
    }
  }}
`;

const Text = styled.p<{ bigSize: boolean }>`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: normal;

  ${({ bigSize }) => {
    switch (bigSize) {
      case true: {
        return css`
          font-size: 16px;
          line-height: 20px;
        `;
      }
      default: {
        return css`
          font-size: 14px;
          line-height: 17px;
        `;
      }
    }
  }}
`;

const ripple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  99% {
    transform: scale(50);
    opacity: 0;
  }
  100% {
    transform: scale(0);
  }
`;

const Circle = styled.div<ICircle>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.buttons.click};
    border-radius: 500px;
    pointer-events: none;
    transform: scale(0);
    animation: ${ripple} 0.75s 1;
    top: ${({ position }) => position.top};
    left: ${({ position }) => position.left};
    width: 10px;
    height: 10px;
  }
`;

const Button = ({
  description,
  bigSize = false,
  onClick,
  disabled = false,
  type,
  ...rest
}: ButtonType) => {
  const [circle, setCircle] = useState<null | React.HTMLProps<ICircle>>(null);

  const createRipple = (x: number, y: number) => {
    setCircle(null);
    setTimeout(() => {
      setCircle(<Circle position={{ top: y + 'px', left: x + 'px' }} />);
    }, 1);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { offsetX, offsetY } = e.nativeEvent;
    createRipple(offsetX, offsetY);

    if (onClick) onClick(e);
  };

  return (
    <ButtonWrapper
      disabled={disabled}
      bigSize={bigSize}
      type={type}
      onClick={handleClick}
      {...rest}
    >
      {circle}
      <Text bigSize={bigSize}>{description}</Text>
    </ButtonWrapper>
  );
};

export default Button;
