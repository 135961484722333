import React, { useState, useEffect, useRef, Children } from 'react';
import TabMenuItem from './TabMenuItem';
import styled from 'styled-components';
import { useLocation } from 'react-router';

export interface ITabMenu extends React.HTMLAttributes<HTMLUListElement> {
  children: React.ReactNode[];
  activeColor?: string;
  noActiveColor?: string;
  reRender?: any;
  withUnderline?: boolean;
  setMarginRight?: number;
  activeElementClass?: string;
}

const TabMenuWrapper = styled.ul.attrs((props) => {})`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & .marker {
    position: absolute;
    left: ${({ setLeft }) => `${setLeft}px`};
    bottom: -5px;
    border-radius: 74px;
    height: 4px;
    width: 28px;
    background: ${({ theme }) => theme.colors.buttons.hover};
    transition: 0.5s;
  }
`;
const TabMenu: React.FC<ITabMenu> = ({
  children,
  activeColor,
  noActiveColor,
  reRender,
  setMarginRight = 16,
  withUnderline = false,
  activeElementClass,
  ...rest
}) => {
  const [offSetLeft, setOffSetLeft] = useState<number | undefined>(0);
  const [widthItem, setWidthItem] = useState<number | undefined>(0);
  const [activeItem, setActiveItem] = useState<number>(0);
  const [offSetBottom, setOffSetBottom] = useState<number | undefined>(0);
  const menuRef = useRef<any>(null);

  const location = useLocation();

  useEffect(() => {
    if (!activeElementClass) {
      setOffSetLeft(menuRef?.current?.childNodes[0]?.offsetLeft);
      setWidthItem(menuRef?.current?.childNodes[0]?.offsetWidth);
      setOffSetBottom(menuRef?.current?.childNodes[0]?.offsetTop);
      setActiveItem(0);
    }
  }, [Children.toArray(children).length, reRender]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeElementClass) {
        const activeTab = menuRef.current.querySelector(`.${activeElementClass}`) as HTMLElement;
        if (activeTab) {
          setOffSetLeft(activeTab?.offsetLeft);
          setWidthItem(activeTab?.offsetWidth);
          setOffSetBottom(activeTab?.offsetTop);
          const key = activeTab.dataset.key;
          setActiveItem(Number(key));
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [location.pathname]);

  const onClickHandler = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    currentRef: any,
    value: number,
  ) => {
    setOffSetLeft(currentRef?.current?.offsetLeft);
    setWidthItem(currentRef?.current?.offsetWidth);
    setOffSetBottom(currentRef?.current?.offsetTop);
    setActiveItem(value);
  };
  return (
    <TabMenuWrapper
      setLeft={offSetLeft}
      widthItem={widthItem}
      setBottom={offSetBottom}
      {...rest}
      ref={menuRef}
    >
      {children.map((el, index) => (
        <TabMenuItem
          clicked={onClickHandler}
          dataSet={index}
          key={`TabMenuItem${index}`}
          activeItem={activeItem}
          activeColor={activeColor}
          noActiveColor={noActiveColor}
          withUnderline={withUnderline}
          setMarginRight={setMarginRight}
        >
          {el}
        </TabMenuItem>
      ))}
      {withUnderline && <div className="marker" data-testid="marker"></div>}
    </TabMenuWrapper>
  );
};

export default TabMenu;
