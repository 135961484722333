import * as S from './styled';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '../../../../hooks';
import { NavigationProps } from '../../AdminLayout';
import { Icon } from '../../../../components';
import { theme } from '../../../../style';
import hexRgb from 'hex-rgb';
interface IMenu {
  onHandleToggle: () => void;
  data: NavigationProps[];
  adminName: string;
}

const Menu = ({ onHandleToggle, data, adminName }: IMenu) => {
  const isMedium = useMediaQuery('(max-width: 768px)');
  return (
    <S.Container>
      <S.Text>
        Hey, <span>{adminName.length > 11 ? `${adminName.substring(0, 11)}***` : adminName}</span>
      </S.Text>

      <S.MenuList>
        {data.map((el) => (
          <S.MenuItem key={el.id} data-testid="li-element">
            <NavLink
              exact={el.isExact}
              to={el.to}
              activeClassName={el.activeClass}
              onClick={isMedium ? onHandleToggle : undefined}
              data-testid="a-element"
            >
              <Icon
                name={el.imageName}
                color={hexRgb(theme.colors.black, { format: 'css', alpha: 0.7 })}
                style={{ marginRight: '12px' }}
              />
              {el.name}
            </NavLink>
          </S.MenuItem>
        ))}
      </S.MenuList>
    </S.Container>
  );
};

export default Menu;
