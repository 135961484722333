import styled from 'styled-components';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

export interface LabelProps extends React.ComponentPropsWithoutRef<'label'> {
  tooltipText?: React.ReactNode;
}

const LabelElement = styled.label`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.default50};
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label: React.FC<LabelProps> = ({ tooltipText, children, ...rest }) => {
  return (
    <LabelElement {...rest}>
      {children}
      {tooltipText ? <Tooltip infoText={tooltipText} className="ml-2.5" /> : null}
    </LabelElement>
  );
};

export default Label;
