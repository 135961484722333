import styled from 'styled-components';
import FarmIcon from '../../assets/farm.svg';

const ImgWrapper = styled.img`
  width: 144px;
`;

export type SingleFarmProps = React.HTMLAttributes<HTMLImageElement>;

const SingleFarm = ({ ...rest }: SingleFarmProps) => {
  return <ImgWrapper src={FarmIcon} alt="Farm icon" {...rest} />;
};

export default SingleFarm;
