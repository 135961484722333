import { AxiosError } from 'axios';

type ApiError = {
  error: {
    statusCode: number;
    name: string;
    message: string;
  };
};

const isMetamaskApiError = (error: any): error is AxiosError<ApiError> => {
  if (error && error.code && error.message && error.stack) {
    return true;
  }

  return false;
};

export default isMetamaskApiError;
