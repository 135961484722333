import { include } from 'named-urls';

const routes = {
  home: '/',
  dashboard: include('/dashboard/', {
    self: '',
    setup: 'setup',
    addFunds: 'add-funds',
    nickname: 'nickname',
    email: 'email',
    changeWallet: 'change-wallet',
    unstake: 'unstake',
    lottery: 'lottery',
    lotteryWinners: 'lottery-winners',
  }),
  admin: include('/admin/', {
    self: '',
    users: include('users/', {
      self: '',
      userDetails: ':id/',
    }),
    withdrawals: 'withdrawals',
    stakingHistory: include('staking-history/', {
      self: '',
      stakingDayHistory: ':date/',
    }),
    loginAttempts: 'login-attempts',
    settings: 'settings',
    balance: 'balance',
    lotteryCumulative: 'lottery-cumulative',
    lotteryWins: include('lottery-wins/', {
      self: '',
      details: ':id/',
    }),
    stakingGains: include('staking-gains/', {
      self: '',
      details: ':date/',
    }),
  }),
};

export default routes;
