import styled from 'styled-components';
import { Tooltip } from '..';
import { convertNumberToString } from '../../utils/convertNumberToString';

export type LabelEarningPositionType = {
  description: string;
  value: number | string;
  displayBorderBottom?: boolean;
  displayBorderTop?: boolean;
  infoText: JSX.Element;
  backgroundColorLIght?: boolean;
  postValueDescription?: string;
} & React.HTMLAttributes<HTMLDivElement>;

type IWrapper = {
  displayBorderBottom?: boolean;
  displayBorderTop?: boolean;
  backgroundColorLIght?: boolean;
};

const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 28px;
  background-color: ${({ backgroundColorLIght, theme }) =>
    backgroundColorLIght ? theme.colors.labels.lightGreen : theme.colors.labels.green};
  border-bottom: ${({ theme, displayBorderBottom }) =>
    displayBorderBottom ? `1px dashed ${theme.colors.labels.border}` : null};
  border-top: ${({ theme, displayBorderTop }) =>
    displayBorderTop ? `1px dashed ${theme.colors.labels.border}` : null};
  z-index: ${({ theme }) => theme.zIndex.centerCard};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.default};
  white-space: nowrap;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const Value = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  padding: 0 16px;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.green};
  z-index: ${({ theme }) => theme.zIndex.centerCardText};
  ${({ theme }) => theme.themeHelpers.breakpoints.down('md')} {
    font-size: 26px;
    line-height: 30px;
  }
`;

const LabelEarningPosition = ({
  description,
  value,
  displayBorderBottom,
  displayBorderTop,
  infoText,
  postValueDescription,
  ...rest
}: LabelEarningPositionType) => {
  return (
    <Wrapper
      {...rest}
      displayBorderBottom={displayBorderBottom}
      displayBorderTop={displayBorderTop}
      data-testId="Wrapper"
    >
      <InnerWrapper>
        <Text>{description}</Text>
        <Tooltip infoText={infoText} />
      </InnerWrapper>
      <Value>
        {convertNumberToString(value)} {postValueDescription ? postValueDescription : null}
      </Value>
    </Wrapper>
  );
};

export default LabelEarningPosition;
