import axios from 'axios';
import config from '../config';
import { refreshAccessToken } from './users';

const apiConfig = {
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const apiCall = axios.create(apiConfig);
export const apiCallWithAuthToken = axios.create(apiConfig);

apiCallWithAuthToken.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const errorResponseInterceptor = async (error: any) => {
  const originalRequest = error.config;

  const { status } = error.response;

  if (status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;

    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      return Promise.reject(error);
    }

    const { data } = await refreshAccessToken(refreshToken);

    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);

    return apiCallWithAuthToken(originalRequest);
  }

  return Promise.reject(error);
};

apiCallWithAuthToken.interceptors.response.use((response) => response, errorResponseInterceptor);
