import React, { useRef } from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.li.attrs((props) => {})`
  margin: ${({ setMarginRight }) => (setMarginRight ? `0 ${setMarginRight}px 0 0` : '0 16px 0 0')};
  @media (max-width: 576px) {
    margin: 0 8px 0 0;
  }

  &:last-of-type {
    margin: 0;
  }

  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: ${({ withUnderline }) => (withUnderline ? '400' : '700')};
  font-size: 15px;
  line-height: 17px;
  display: flex;
  display: block;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 1px;
  color: ${({ theme, activeItem, dataSet, activeColor, noActiveColor }) =>
    activeItem === dataSet
      ? `${activeColor || theme.colors.default} !important`
      : `${noActiveColor || theme.colors.default50} !important`};
  transition: color 0.15s linear;

  svg path {
    transition: all 0.15s linear;
    fill: ${({ theme, activeItem, dataSet, activeColor, noActiveColor }) =>
      activeItem === dataSet
        ? `${activeColor || theme.colors.default} !important`
        : `${noActiveColor || theme.colors.default50} !important`};
    fill-opacity: 1;
  }

  & > * {
    color: ${({ theme, activeItem, dataSet, activeColor, noActiveColor }) =>
      activeItem === dataSet
        ? `${activeColor || theme.colors.default} !important`
        : `${noActiveColor || theme.colors.default50} !important`};
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
`;

interface ITabMenuItem {
  children: React.ReactNode;
  setMarginRight: number;
  activeItem: number;
  clicked: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    currentRef: any,
    value: number,
  ) => void;
  dataSet: any;
  activeColor?: string;
  noActiveColor?: string;
  withUnderline?: boolean;
}

const TabMenuItem: React.FC<ITabMenuItem> = ({
  setMarginRight,
  children,
  clicked,
  dataSet,
  activeItem,
  activeColor,
  noActiveColor,
  withUnderline,
}) => {
  const itemRef = useRef(null);
  return (
    <ItemWrapper
      setMarginRight={setMarginRight}
      activeColor={activeColor}
      noActiveColor={noActiveColor}
      onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        clicked(event, itemRef, dataSet);
      }}
      ref={itemRef}
      activeItem={activeItem}
      dataSet={dataSet}
      withUnderline={withUnderline}
    >
      {children}
    </ItemWrapper>
  );
};

export default TabMenuItem;
