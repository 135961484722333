import { useEffect, useState } from 'react';

const useMediaQuery = (query: string) => {
  const mediaQuery = window.matchMedia(query);

  const [match, setMatch] = useState(!!mediaQuery.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatch(e.matches);

    mediaQuery.addEventListener('change', handler);

    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  return match;
};

export default useMediaQuery;
