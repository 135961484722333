import { walletActions } from '../..';
import apiCall from '../../../api';
import * as alertActions from '../alertActions/alertActions';
import { ethers } from 'ethers';
import { removeValue, storeValue } from '../../../api/localstorage';
import { Dispatch } from 'redux';
import { isApiError, isMetamaskApiError } from '../../../utils';
import { getUsersMe } from '../../../api/users';
import { fetchUserPositionECY } from '../../../services/staking.service';

export enum UsersActionTypes {
  LOGOUT_USER = 'LOGOUT_USER',
  FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE',
  KEEP_LOGGED_IN = 'KEEP_LOGGED_IN',
  FETCH_USER_ECY_POSITION_SUCCESS = 'FETCH_USER_ECY_POSITION_SUCCESS',
}

export const provideSignature = async (
  accessCode: string,
  walletAddress: string,
  dispatch: Dispatch,
) => {
  if (window.ethereum) {
    try {
      await window.ethereum.enable();
      let provider = await new ethers.providers.Web3Provider(window.ethereum);
      let signer = await provider.getSigner();
      const signedAccessCode = await signer.signMessage(accessCode);
      try {
        const authenticationRes = await apiCall.users.postUsersLogin(
          walletAddress,
          signedAccessCode,
        );
        storeValue('accessToken', authenticationRes.data.accessToken);
        storeValue('refreshToken', authenticationRes.data.refreshToken);
        dispatch(
          alertActions.success({
            message: 'You have logged in! Feel free to use ecoway.',
          }),
        );
      } catch (err) {
        if (isApiError(err)) {
          dispatch(
            alertActions.error({
              message:
                err.response?.data.error.message ||
                `Error has occurred. We couldn't authorize you.`,
            }),
          );
        } else {
          dispatch(
            alertActions.error({
              message: `Error has occurred. We couldn't authorize you.`,
            }),
          );
        }
      }
    } catch (err) {
      if (isMetamaskApiError(err)) {
        dispatch(
          alertActions.error({
            message: err.message,
          }),
        );
      } else {
        dispatch(
          alertActions.error({
            message: 'Error with Metamask has occurred.',
          }),
        );
      }
    }
  }
};

export const loginUser = () => async (dispatch: Dispatch, getState: () => RootStore) => {
  const walletState = getState().wallet;
  if (walletState?.address !== '') {
    try {
      const response = await apiCall.users.getUsersLogin(walletState.address);
      await provideSignature(response.data.accessCode, walletState.address, dispatch);
    } catch (err) {
      if (isApiError(err)) {
        dispatch(
          alertActions.error({
            message:
              err.response?.data.error.message || `Error has occurred. We couldn't login you.`,
          }),
        );
      } else {
        dispatch(
          alertActions.error({
            message: `Error has occurred. We couldn't login you.`,
          }),
        );
      }
    }
  } else {
    dispatch(
      alertActions.warning({
        message: 'Your wallet is not connected. Connect Metamask wallet first.',
      }),
    );
  }
};

type LogoutUserActionType = {
  type: UsersActionTypes.LOGOUT_USER;
};

export const logoutUser = () => (dispatch: Dispatch) => {
  removeValue('accessToken');
  removeValue('refreshToken');
  dispatch(walletActions.resetWalletState());
  dispatch({ type: UsersActionTypes.LOGOUT_USER });
  dispatch(
    alertActions.info({
      heading: 'Good bye!',
      message: 'Your have logged out.',
    }),
  );
};

export const fetchLoggedUser = () => async (dispatch: Dispatch<any>) => {
  try {
    const response = await getUsersMe();
    dispatch({
      type: UsersActionTypes.FETCH_USER_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: UsersActionTypes.FETCH_USER_DATA_FAILURE,
    });
  }
};

export const fetchYourECYPosition =
  () => async (dispatch: Dispatch<any>, getState: () => RootStore) => {
    try {
      const store = getState();
      const response = await fetchUserPositionECY(store.users.data.walletAddress);
      dispatch({
        type: UsersActionTypes.FETCH_USER_ECY_POSITION_SUCCESS,
        payload: response,
      });
    } catch (e) {
      dispatch(
        alertActions.error({
          message: `Error has occurred. We couldn't load your ECY balance`,
        }),
      );
    }
  };

type FetchUserDataFailureActionType = {
  type: UsersActionTypes.FETCH_USER_DATA_FAILURE;
};

type FetchUserDataSuccessAction = {
  type: UsersActionTypes.FETCH_USER_DATA_SUCCESS;
  payload: UsersObject;
};

type FetchUserECYPositionSuccesActionType = {
  type: UsersActionTypes.FETCH_USER_ECY_POSITION_SUCCESS;
  payload: string;
};

export type UsersAction =
  | LogoutUserActionType
  | FetchUserDataSuccessAction
  | FetchUserDataFailureActionType
  | FetchUserECYPositionSuccesActionType;
