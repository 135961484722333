import styled from 'styled-components';
import { convertNumberToString } from '../../utils/convertNumberToString';

export type ValueLabelType = {
  description: string;
  value: number;
} & React.HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.p`
  font-family: 'Lexend Deca';
  font-size: 18px;
  line-height: 22.5px;
  text-align: center;
  color: ${({ theme }) => theme.colors.default};
  margin: 0;
  margin-bottom: 4px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Value = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  color: ${({ theme }) => theme.colors.green};
  margin: 0;

  ${({ theme }) => theme.themeHelpers.breakpoints.down('md')} {
    font-size: 26px;
    line-height: 30px;
  }
`;

const ValueLabel = ({ description, value, ...rest }: ValueLabelType) => {
  return (
    <Wrapper {...rest}>
      <Description>{description}</Description>
      <Value> {convertNumberToString(value)}</Value>
    </Wrapper>
  );
};

export default ValueLabel;
