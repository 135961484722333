import { apiCallWithAuthToken } from './config';

export const setRoles = (id: number, adminRole: boolean, userRole: boolean) =>
  apiCallWithAuthToken.patch('/admin/set-role', { userId: id, admin: adminRole, user: userRole });

export const setSuperOwner = (id: number) =>
  apiCallWithAuthToken.patch('/admin/transfer-super-owner', { userId: id });

export const getAllLoginAttempts = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; loginAttempts: LoginAttemptsType[] }>(
    `/login-attempts${filter ? `?filter=${JSON.stringify(filter)}` : null}`,
  );

export const getAllWithdrawals = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; withdrawals: Withdrawal[] }>(
    `/withdrawals${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );
