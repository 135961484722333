import React, { useCallback, useState } from 'react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import * as S from './styled';
import { routes } from '../../utils';
import { IconName } from '../../components/Icon/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from '../../store';
interface IAdminLayout {
  children: React.ReactNode;
}

export interface NavigationProps {
  id: number;
  isExact: boolean;
  name: string;
  imageName: IconName;
  activeClass: string;
  to: string;
}
const navigation: NavigationProps[] = [
  {
    id: 1,
    isExact: true,
    name: 'Dashboard',
    imageName: 'feather-grid',
    activeClass: 'active',
    to: routes.admin.self,
  },
  {
    id: 2,
    isExact: false,
    name: 'Users',
    imageName: 'feather-users',
    activeClass: 'active',
    to: routes.admin.users.self,
  },
  {
    id: 3,
    isExact: false,
    name: 'Lottery Cumulative',
    imageName: 'unicons-medal',
    activeClass: 'active',
    to: routes.admin.lotteryCumulative,
  },
  {
    id: 4,
    isExact: false,
    name: 'Lottery wins',
    imageName: 'unicons-trophy',
    activeClass: 'active',
    to: routes.admin.lotteryWins.self,
  },
  {
    id: 5,
    isExact: false,
    name: 'Staking gains',
    imageName: 'unicons-coins',
    activeClass: 'active',
    to: routes.admin.stakingGains.self,
  },
  {
    id: 6,
    isExact: false,
    name: 'Withdrawals',
    imageName: 'unicons-money-withdrawal',
    activeClass: 'active',
    to: routes.admin.withdrawals,
  },
  {
    id: 7,
    isExact: false,
    name: 'Balance',
    imageName: 'unicons-university',
    activeClass: 'active',
    to: routes.admin.balance,
  },
  {
    id: 8,
    isExact: false,
    name: 'Login Attempts',
    imageName: 'feather-alert-circle',
    activeClass: 'active',
    to: routes.admin.loginAttempts,
  },
  {
    id: 9,
    isExact: false,
    name: 'Staking history',
    imageName: 'feather-bar-chart-2',
    activeClass: 'active',
    to: routes.admin.stakingHistory.self,
  },
  {
    id: 10,
    isExact: false,
    name: 'Settings',
    imageName: 'unicons-setting',
    activeClass: 'active',
    to: routes.admin.settings,
  },
  {
    id: 11,
    isExact: false,
    name: 'User Panel',
    imageName: 'feather-layout',
    activeClass: 'active',
    to: routes.dashboard.self,
  },
];

const AdminLayout = ({ children }: IAdminLayout) => {
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const userData = useSelector((store: RootStore) => store.users.data);
  const handleToggleMenu = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);

  const handleDisconnect = async () => {
    dispatch(usersActions.logoutUser());
  };

  return (
    <S.Background>
      <Header onHandleToggle={handleToggleMenu} onHandleDisconnect={handleDisconnect} />
      <AnimateSharedLayout>
        <S.Wrapper layout>
          <S.AsideMenu layout>
            <AnimatePresence>
              {isMenuOpen && (
                <motion.div
                  layout
                  initial={{ opacity: 0.8, x: -354 }}
                  transition={{ duration: 0.35 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 0 }}
                >
                  <Menu
                    onHandleToggle={handleToggleMenu}
                    data={navigation}
                    adminName={
                      userData.nickname === null || userData.nickname.length === 0
                        ? userData.walletAddress
                        : userData.nickname
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </S.AsideMenu>
          <S.Content isMenuOpen={isMenuOpen}>{children}</S.Content>
        </S.Wrapper>
      </AnimateSharedLayout>
    </S.Background>
  );
};

export default React.memo(AdminLayout);
