import styled from 'styled-components';
import hexRgb from 'hex-rgb';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 354px;
  height: calc(100vh - 56px);
  margin: 0;
  padding: 0 17px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: auto;
  ${({ theme }) => theme.themeHelpers.breakpoints.down('md')} {
    max-width: 100vw;
  }
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 5px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttons.grayText};
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.buttons.green};
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-top: 24px;
  span {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const MenuList = styled.ul`
  margin: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
`;

export const MenuItem = styled.li`
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 57px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 0 17px;
    text-align: center;
    border: 2px solid ${({ theme }) => theme.colors.buttons.gray};
    margin-top: 4px;
    border-radius: 4px;
    transition: 0.25s;
    color: ${({ theme }) => hexRgb(theme.colors.black, { format: 'css', alpha: 0.7 })};

    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.green};
    }

    &.active {
      border: 2px solid ${({ theme }) => theme.colors.green};
      background-color: ${({ theme }) => hexRgb(theme.colors.green, { format: 'css', alpha: 0.1 })};
    }
  }
`;
