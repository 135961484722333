import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import hexRgb from 'hex-rgb';
import { theme } from '../../style';

export type SortIconProps = {
  order: 'DESC' | 'ASC' | null;
} & HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div`
  position: relative;
  width: 20px;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  i:first-child {
    &::before {
      transform: translateY(6px);
    }
  }

  i:last-child {
    &::before {
      transform: translateY(-6px);
    }
  }

  i,
  i::before {
    transition: 0.3s;
  }
`;

const SortIcon = ({ order, ...rest }: SortIconProps) => {
  return (
    <Wrapper {...rest}>
      <div>
        <Icon
          name="unicons-angle-up"
          color={
            order === 'ASC'
              ? hexRgb(theme.colors.black, { format: 'css', alpha: 0.7 })
              : theme.colors.default
          }
          fontSize="19px"
          data-testid="sort-asc"
        />
        <Icon
          name="unicons-angle-down"
          color={
            order === 'DESC'
              ? hexRgb(theme.colors.black, { format: 'css', alpha: 0.7 })
              : theme.colors.default
          }
          fontSize="19px"
          data-testid="sort-desc"
        />
      </div>
    </Wrapper>
  );
};

export default SortIcon;
