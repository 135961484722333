import { Dispatch } from 'redux';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { truncateWalletAddress } from '../../../utils';
import * as alertActions from '../alertActions/alertActions';
import { addBscNetworkToMetamask } from '../../../helpers';
import config from '../../../config';
import BigNumber from 'bignumber.js';
import { ERC20Abi } from '../../../abi/erc20.abi';
import { FetchUserInfoAfterStakingType } from '../../../services/staking.service';
import { logoutUser, provideSignature } from '../usersActions/usersActions';
import { usersActions } from '../../index';
import apiCall from '../../../api';

let eventListenerModeOn = true;

export enum WalletActionTypes {
  CONNECT_WALLET_PROCESS_START = 'CONNECT_WALLET_PROCESS_START',
  CONNECT_WALLET_PROCESS_END = 'CONNECT_WALLET_PROCESS_END',
  CONNECT_WALLET_PENDING = 'CONNECT_WALLET_PENDING',
  CONNECT_WALLET_SUCCESS = 'CONNECT_WALLET_SUCCESS',
  CONNECT_WALLET_FAILURE = 'CONNECT_WALLET_FAILURE',
  RESET_WALLET_STATE = 'RESET_WALLET_STATE',
  FETCH_USER_BALANCE = 'FETCH_USER_BALANCE',
  FETCH_USER_INFO_AFTER_STAKING = 'FETCH_USER_INFO_AFTER_STAKING',
}

type EndWalletConnectingActionType = {
  type: WalletActionTypes.CONNECT_WALLET_PROCESS_END;
};

export const endWalletConnecting = (): EndWalletConnectingActionType => ({
  type: WalletActionTypes.CONNECT_WALLET_PROCESS_END,
});

type StartWalletConnectingActionType = {
  type: WalletActionTypes.CONNECT_WALLET_PROCESS_START;
};

export const startWalletConnecting = (): StartWalletConnectingActionType => ({
  type: WalletActionTypes.CONNECT_WALLET_PROCESS_START,
});

type ConnectWalletPendingActionType = {
  type: WalletActionTypes.CONNECT_WALLET_PENDING;
  payload: ConnectWalletMethod;
};

export const connectWalletPending = (
  method: ConnectWalletMethod,
): ConnectWalletPendingActionType => ({
  type: WalletActionTypes.CONNECT_WALLET_PENDING,
  payload: method,
});

type ConnectWalletSuccessActionType = {
  type: WalletActionTypes.CONNECT_WALLET_SUCCESS;
  payload: any;
};

export const connectWalletSuccess = (walletData: WalletData): ConnectWalletSuccessActionType => ({
  type: WalletActionTypes.CONNECT_WALLET_SUCCESS,
  payload: walletData,
});

type ConnectWalletFailureActionType = {
  type: WalletActionTypes.CONNECT_WALLET_FAILURE;
  payload: any;
};

export const connectWalletFailure = (error: string): ConnectWalletFailureActionType => ({
  type: WalletActionTypes.CONNECT_WALLET_FAILURE,
  payload: error,
});

type ResetWalletStateActionType = {
  type: WalletActionTypes.RESET_WALLET_STATE;
};

export const resetWalletState = (): ResetWalletStateActionType => {
  localStorage.setItem('LAST_CONNECTED_WALLET', '');

  return {
    type: WalletActionTypes.RESET_WALLET_STATE,
  };
};

export type FetchUserBalanceType = {
  type: WalletActionTypes.FETCH_USER_BALANCE;
  balance: string;
};

export let web3Provider: ethers.providers.Web3Provider;
export let signer: ethers.providers.JsonRpcSigner;

export const fetchUserBalance = () => async (dispatch: Dispatch) => {
  try {
    const from = await signer.getAddress();
    const contract = new ethers.Contract(config.TOKEN_ADDRESS, ERC20Abi, signer);
    const balance = await contract.balanceOf(from);

    const userBalance = new BigNumber(balance.toString())
      .dividedBy(new BigNumber(10).exponentiatedBy(18))
      .toString();

    dispatch({
      type: WalletActionTypes.FETCH_USER_BALANCE,
      balance: userBalance,
    });
  } catch (e) {
    dispatch(
      alertActions.error({
        heading: 'Fetching wallet balance!',
        message: 'Error has occurred, please try again later.',
      }),
    );
  }
};

export const onInvalidNetwork = (
  method: ConnectWalletMethod,
  windowObj: Record<string, any>,
  dispatch: Dispatch,
  message: string = 'Please set up Binance Smart Chain network.',
) => {
  dispatch(connectWalletFailure('Invalid network'));
  dispatch(
    alertActions.warning({
      heading: 'Woops... Invalid network.',
      message: message,
    }),
  );

  if (method === 'METAMASK' || method === 'TRUST_WALLET' || method === 'TOKEN_POCKET') {
    addBscNetworkToMetamask(dispatch);
  }
  if (method === 'WALLET_CONNECT') {
    windowObj.disconnect();
  }
};

export const connectByWeb3Provider = async (
  provider: any,
  connectMethod: ConnectWalletMethod,
  dispatch: Dispatch<any>,
  onValid: typeof onInvalidNetwork = onInvalidNetwork,
) => {
  web3Provider = await new ethers.providers.Web3Provider(provider, 'any');

  const network = await web3Provider.getNetwork();

  if (network.chainId !== config.NETWORK_ID) {
    onValid(connectMethod, provider, dispatch);
    //dispatch(tokensActions.resetTokensBalance());
    return;
  }
  localStorage.setItem('LAST_CONNECTED_WALLET', connectMethod);

  signer = web3Provider.getSigner();
  const address = await signer.getAddress();
  const balance = await signer.getBalance();

  const walletData = {
    address,
    balance: Number(balance.toString()) / Math.pow(10, 18),
    connectMethod,
    network: network.chainId,
  };

  dispatch(connectWalletSuccess(walletData));
  //dispatch(tokensActions.updateTokensBalance());
  return walletData;
};

export const onAccountChanged = (
  windowObj: Record<string, any>,
  method: ConnectWalletMethod,
  dispatch: Dispatch<any>,
  connectWallet: typeof connectByWeb3Provider = connectByWeb3Provider,
) => {
  windowObj.on('accountsChanged', async (accounts: string[]) => {
    dispatch(logoutUser());
    const [address] = accounts;
    if (address) {
      connectWallet(windowObj, method, dispatch, onInvalidNetwork);
      const response = await apiCall.users.getUsersLogin(address);
      await provideSignature(response.data.accessCode, address, dispatch);
      await dispatch(usersActions.fetchLoggedUser());
    } else {
      dispatch(resetWalletState());
    }
  });
};

export const onDisconnect = (windowObj: Record<string, any>, dispatch: Dispatch<any>) => {
  windowObj.on('disconnect', () => {
    dispatch(resetWalletState());
  });
};

export const onChainChanged = (
  windowObj: Record<string, any>,
  method: ConnectWalletMethod,
  dispatch: Dispatch,
  connectWallet: typeof connectByWeb3Provider = connectByWeb3Provider,
) => {
  windowObj.on('chainChanged', () => {
    connectWallet(windowObj, method, dispatch, onInvalidNetwork);
  });
};

export const connectByEthereumProvider =
  (
    method: ConnectWalletMethod,
    windowObj: Window & typeof globalThis = window,
    connectWallet: typeof connectByWeb3Provider = connectByWeb3Provider,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(connectWalletPending(method));
    try {
      if (windowObj.ethereum) {
        await windowObj.ethereum.request({ method: 'eth_requestAccounts' });
        if (eventListenerModeOn) {
          onAccountChanged(windowObj.ethereum, method, dispatch);
          onChainChanged(windowObj.ethereum, method, dispatch);
          onDisconnect(windowObj.ethereum, dispatch);
          eventListenerModeOn = false;
        }
        const walletData = await connectWallet(
          windowObj.ethereum,
          method,
          dispatch,
          onInvalidNetwork,
        );
        if (walletData?.address) {
          dispatch(
            alertActions.success({
              heading: 'Your wallet is connected.',
              message: `Wallet ${truncateWalletAddress(
                walletData.address,
              )} has been successfully installed with ${
                method === 'METAMASK'
                  ? 'Metamask'
                  : method === 'TRUST_WALLET'
                  ? 'TrustWallet'
                  : 'TokenPocket'
              }.`,
            }),
          );
          setTimeout(() => dispatch(endWalletConnecting()), 2000);
        } else {
          dispatch(endWalletConnecting());
        }
      } else {
        dispatch(connectWalletFailure('No provider was found'));
        dispatch(
          alertActions.warning({
            heading: 'Woops... we cannot add your wallet.',
            message: 'No provider was found.',
          }),
        );
        dispatch(endWalletConnecting());
      }
    } catch {
      dispatch(connectWalletFailure('Connection to wallet failed'));
      dispatch(
        alertActions.warning({
          heading: 'Woops... we cannot add your wallet.',
          message: 'Please try again.',
        }),
      );
      dispatch(endWalletConnecting());
    }
  };

export const connectByWalletConnect =
  (
    method: ConnectWalletMethod,
    wcprovider: WalletConnectProvider = new WalletConnectProvider({
      rpc: {
        1: 'https://mainnet.infura.io/v3/' + config.INFURA_KEY,
        4: 'https://rinkeby.infura.io/v3/' + config.INFURA_KEY,
        56: 'https://bsc-dataseed3.defibit.io/',
        97: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
      },
      chainId: 56,
    }),
    connectWallet: typeof connectByWeb3Provider = connectByWeb3Provider,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(connectWalletPending(method));

    try {
      if (wcprovider) {
        await wcprovider.enable();

        onAccountChanged(wcprovider, method, dispatch);
        onChainChanged(wcprovider, method, dispatch);
        onDisconnect(wcprovider, dispatch);
        const walletData = await connectWallet(wcprovider, method, dispatch);

        if (walletData?.address) {
          dispatch(
            alertActions.success({
              heading: 'Your wallet is ready to use!',
              message: `Wallet ${truncateWalletAddress(
                walletData.address,
              )} has been successfully installed with Wallet Connect.`,
            }),
          );
          setTimeout(() => dispatch(endWalletConnecting()), 2000);
        } else {
          dispatch(endWalletConnecting());
        }
      }
    } catch (e) {
      dispatch(connectWalletFailure('Connection to wallet failed'));
      dispatch(
        alertActions.warning({
          heading: 'Woops... we cannot add your wallet.',
          message: 'Please try again.',
        }),
      );
      dispatch(endWalletConnecting());
    }
  };

export const connectByBinanceChainProvider =
  (
    method: ConnectWalletMethod,
    windowObj: Window & typeof globalThis = window,
    connectWallet: typeof connectByWeb3Provider = connectByWeb3Provider,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(connectWalletPending(method));
    try {
      if (windowObj.BinanceChain) {
        await windowObj.BinanceChain.enable();

        onAccountChanged(windowObj.BinanceChain, method, dispatch);
        onChainChanged(windowObj.BinanceChain, method, dispatch);
        onDisconnect(windowObj.BinanceChain, dispatch);

        const walletData = await connectWallet(window.BinanceChain, 'BINANCE_CHAIN', dispatch);
        if (walletData?.address) {
          dispatch(
            alertActions.success({
              heading: 'Your wallet is ready to use!',
              message: `Wallet ${truncateWalletAddress(
                walletData.address,
              )} has been successfully installed with Binance Chain wallet`,
            }),
          );
          setTimeout(() => dispatch(endWalletConnecting()), 2000);
        } else {
          dispatch(endWalletConnecting());
        }
      } else {
        dispatch(connectWalletFailure('No provider was found'));
        dispatch(
          alertActions.warning({
            heading: 'Woops... we cannot add your wallet.',
            message: 'No provider was found.',
          }),
        );
        dispatch(endWalletConnecting());
      }
    } catch (e) {
      dispatch(connectWalletFailure('Connection to wallet failed'));
      dispatch(
        alertActions.warning({
          heading: 'Woops... we cannot add your wallet.',
          message: 'Please try again.',
        }),
      );
      dispatch(endWalletConnecting());
    }
  };

export const autoConnectEthereumProvider = () => async (dispatch: Dispatch<any>) => {
  try {
    const connectMethod = localStorage.getItem('LAST_CONNECTED_WALLET') ?? '';

    if (connectMethod === 'METAMASK' && window.ethereum) {
      const w3Provider: ethers.providers.Web3Provider = await new ethers.providers.Web3Provider(
        window.ethereum,
        'any',
      );

      const accounts = await w3Provider.listAccounts();
      if (accounts.length > 0) {
        dispatch(connectByEthereumProvider(connectMethod));
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export type WalletAction =
  | ConnectWalletPendingActionType
  | ConnectWalletSuccessActionType
  | ConnectWalletFailureActionType
  | StartWalletConnectingActionType
  | EndWalletConnectingActionType
  | ResetWalletStateActionType
  | FetchUserBalanceType
  | FetchUserInfoAfterStakingType;
