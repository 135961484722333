import styled from 'styled-components';
import StageIdicator from '../StageIndicator/StageIndicator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HLine = styled.div`
  border-width: 1px;
  border-style: dashed none none none;
  border-color: ${({ theme }) => theme.colors.default};
  width: 79px;
  margin-top: 11.5px;
  margin-left: -10.5px;
  margin-right: -10.5px;
`;

export type ProcessStageType = {
  initialStage: boolean;
  stageNoA: number;
  stageNoB: number;
  descriptionA: string;
  descriptionB: string;
  checkedA?: boolean;
  checkedB?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ProcessStage = ({
  initialStage,
  stageNoA,
  stageNoB,
  descriptionA,
  descriptionB,
  checkedA = false,
  checkedB = false,
  ...args
}: ProcessStageType) => {
  return (
    <Wrapper {...args}>
      <StageIdicator
        active={initialStage}
        stageNo={stageNoA}
        stageDescription={descriptionA}
        checked={checkedA}
      />
      <HLine />
      <StageIdicator
        active={!initialStage}
        stageNo={stageNoB}
        stageDescription={descriptionB}
        checked={checkedB}
      />
    </Wrapper>
  );
};

export default ProcessStage;
