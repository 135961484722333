import * as users from './users';
import * as loginAttempts from './loginAttempts';
import * as admin from './admin';
import * as staking from './staking';
import * as stakingGain from './stakingGain';
import * as lottery from './lottery';
import * as lotteryCumulative from './lotteryCumulative';
import * as balance from './balance';

const api = {
  users,
  admin,
  staking,
  stakingGain,
  lottery,
  lotteryCumulative,
  loginAttempts,
  balance,
};

export default api;
