import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon, { IconProps } from '../Icon/Icon';
import { theme } from '../../style';

export interface PaginatorProps extends React.HTMLAttributes<HTMLDivElement> {
  startPage?: number;
  page?: number;
  pagesNumber: number;
  onPageChange: (newPage: number) => any;
}

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Page = styled.button<React.HTMLProps<HTMLButtonElement> & { active?: boolean }>`
  height: 27px;
  width: 27px;
  line-height: 24px;
  margin: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.default50};
  cursor: pointer;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  ${({ active, theme }) =>
    active
      ? `
    border-radius: 50%;
    background: ${theme.colors.buttons.green};
    color: ${theme.colors.white};
  `
      : ''}
`;

const ButtonIcon = styled(Icon)<IconProps & { disabled?: boolean }>`
  ${({ disabled }) => (disabled ? 'opacity: 0.8;' : 'cursor: pointer;')}
`;

const Dots = styled.span.attrs(({ ...props }) => ({
  children: '...',
  ...props,
}))`
  margin: 0 8px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.default50};
`;

const Paginator: React.FC<PaginatorProps> = ({
  startPage = 1,
  pagesNumber,
  onPageChange,
  page,
  ...rest
}) => {
  const [active, setActive] = useState<number>(startPage);

  useEffect(() => {
    if (page) setActive(page);
  }, [page]);

  const handlePageChange = (pageNumber: number) => {
    onPageChange(pageNumber);
    setActive(pageNumber);
  };

  useEffect(() => {
    if (page) {
      setActive(page);
    }
  }, [page]);

  const generatePagesRange = (from: number, to: number) =>
    range(from, to + 1).map((i) => (
      <Page active={i === active} key={i} onClick={() => handlePageChange(i)}>
        {i}
      </Page>
    ));

  const generatePagination = () => {
    if (pagesNumber < 6) return generatePagesRange(1, pagesNumber);
    if (active <= 3)
      return [
        ...generatePagesRange(1, Math.max(active + 1, 3)),
        <Dots />,
        generatePagesRange(pagesNumber, pagesNumber),
      ];
    if (pagesNumber - active < 3)
      return [
        ...generatePagesRange(1, 1),
        <Dots />,
        generatePagesRange(Math.min(active - 1, pagesNumber - 2), pagesNumber),
      ];
    return [
      ...generatePagesRange(1, 1),
      <Dots />,
      ...generatePagesRange(active - 1, active + 1),
      <Dots />,
      ...generatePagesRange(pagesNumber, pagesNumber),
    ];
  };

  return (
    <Container {...rest}>
      <ButtonIcon
        fontSize={20}
        name="feather-chevron-left"
        color={theme.colors.default}
        disabled={active === 1}
        onClick={() => handlePageChange(Math.max(1, active - 1))}
        data-testid="prev-step"
      />
      <div style={{ margin: '0 6px' }}>{generatePagination()}</div>
      <ButtonIcon
        fontSize={20}
        name="feather-chevron-right"
        color={theme.colors.default}
        disabled={active === pagesNumber}
        onClick={() => handlePageChange(Math.min(active + 1, pagesNumber))}
        data-testid="next-step"
      />
    </Container>
  );
};

export default Paginator;
