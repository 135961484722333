import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { GlobalStyle, theme } from './style';
import './fonts/LexendDeca/index.css';
import './fonts/LexendDeca/LexendDeca-Black.ttf';
import './fonts/LexendDeca/LexendDeca-Bold.ttf';
import './fonts/LexendDeca/LexendDeca-ExtraBold.ttf';
import './fonts/LexendDeca/LexendDeca-ExtraLight.ttf';
import './fonts/LexendDeca/LexendDeca-Light.ttf';
import './fonts/LexendDeca/LexendDeca-Medium.ttf';
import './fonts/LexendDeca/LexendDeca-Regular.ttf';
import './fonts/LexendDeca/LexendDeca-SemiBold.ttf';
import './fonts/LexendDeca/LexendDeca-Thin.ttf';
import './fonts/Roboto/index.css';
import './fonts/Roboto/Roboto-Bold.ttf';
import './fonts/Roboto/Roboto-Light.ttf';
import './fonts/Roboto/Roboto-Italic.ttf';
import './fonts/Roboto/Roboto-Medium.ttf';
import './fonts/Roboto/Roboto-Regular.ttf';
import './fonts/DM_Sans/index.css';
import './fonts/DM_Sans/DMSans-Bold.ttf';
import './fonts/DM_Sans/DMSans-BoldItalic.ttf';
import './fonts/DM_Sans/DMSans-Italic.ttf';
import './fonts/DM_Sans/DMSans-Medium.ttf';
import './fonts/DM_Sans/DMSans-MediumItalic.ttf';
import './fonts/DM_Sans/DMSans-Regular.ttf';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
