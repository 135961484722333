import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

type InputProps = {
  defaultValue?: string | number | null;
  onMaxButtonClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onlyDigits?: boolean;
  suffix?: string;
  maxPrecision?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.input.gray};
  border-radius: 3px;
`;

const Container = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: text;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const InputElement = styled.input<React.HTMLProps<HTMLInputElement>>`
  color: ${({ theme }) => theme.colors.default};
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: calc(100% - 15px);
  outline: none;

  padding: 6px 35px 7px 8px;
  height: 32px;
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.labels.border};
    border-radius: 3px;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.default};
  }
`;

const Currency = styled.span<{ val: any | null }>`
  position: absolute;
  top: 6px;
  left: min(
    calc(${({ val }) => (String(val).length ? String(val).length : 1)}ch + 11px),
    calc(100% - 48px)
  );
  color: ${({ theme }) => theme.colors.default};
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const MaxButton = styled.button`
  color: ${({ theme }) => theme.colors.buttons.green};
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  scale: 1;
  margin-right: 8px;
  &:hover {
    color: ${({ theme }) => theme.colors.buttons.hover};
    scale: 1.05;
    transition: all 0.1s ease-in;
  }
`;

const Input = ({
  defaultValue = '',
  onlyDigits,
  suffix,
  onMaxButtonClick,
  maxPrecision = 18,
  ...rest
}: InputProps) => {
  const [val, setVal] = useState<string | number | undefined>(defaultValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (onlyDigits) {
      if (!RegExp(`^[0-9]*([.|,][0-9]{0,${maxPrecision}})?$`).test(value)) {
        e.preventDefault();
        return;
      }
    }
    setVal(e.target.value);
    if (rest?.onChange) rest.onChange(e);
  };

  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);

  return (
    <Wrapper>
      <Container data-value={val}>
        <InputElement
          {...rest}
          type="text"
          onChange={handleInputChange}
          value={val}
          placeholder="0"
        />
        {suffix ? <Currency val={val}>{suffix}</Currency> : null}
      </Container>
      {onMaxButtonClick ? (
        <MaxButton type="button" onClick={onMaxButtonClick}>
          MAX
        </MaxButton>
      ) : null}
    </Wrapper>
  );
};

export default Input;
