import { useCallback, useMemo, useState } from 'react';
import usePaginator from './usePaginator';

type SortOrder = 'DESC' | 'ASC';

const useTableHandler = (
  pageLimit: number = 15,
  defaultSortedKey?: string,
  defaultSortOrder?: SortOrder,
) => {
  const { Paginator, setElements, elements, page, setPage } = usePaginator(pageLimit);
  const [sortedKey, setSortedKey] = useState<string | null>(defaultSortedKey || null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(defaultSortOrder || 'DESC');

  const sort = useCallback(
    (column: string) => {
      if (sortedKey === column) {
        setSortOrder((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
      } else {
        setSortOrder('DESC');
        setSortedKey(column);
      }
    },
    [sortedKey],
  );

  const filter: ApiFilter = useMemo(
    () => ({
      limit: pageLimit,
      skip: pageLimit * (page - 1),
      order: sortedKey ? `${sortedKey} ${sortOrder}` : undefined,
    }),
    [page, pageLimit, sortedKey, sortOrder],
  );

  return {
    Paginator,
    setElements,
    filter,
    sort,
    setPage,
    sortOrder,
    sortedKey,
    elements,
  };
};

export default useTableHandler;
