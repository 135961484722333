import React, { useEffect } from 'react';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import Alert from './Alert/Alert';
import * as S from './styled';

const alertsRoot = document.createElement('div');
alertsRoot.setAttribute('id', 'alerts');

const AlertsList = () => {
  const alerts = useSelector((store: RootStore) => store.alerts);

  useEffect(() => {
    const init = () => {
      document.body.appendChild(alertsRoot);
    };

    init();

    const remove = () => {
      document.body.removeChild(alertsRoot);
    };
    return () => remove();
  });

  return createPortal(
    <AnimateSharedLayout>
      <S.AlertsWrapper layout hasAlerts={alerts?.length > 0}>
        <S.Wrapper hasAlerts={alerts?.length > 0} data-testid="alerts-list">
          <AnimatePresence>
            {alerts?.map((alert) => (
              <Alert key={alert.id} {...alert} />
            ))}
          </AnimatePresence>
        </S.Wrapper>
      </S.AlertsWrapper>
    </AnimateSharedLayout>,

    alertsRoot,
  );
};

export default AlertsList;
