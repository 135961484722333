import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import EcowayIcon from '../../assets/logo_gradient.svg';

export type LogoProps = React.HTMLAttributes<HTMLButtonElement>;

type ICircle = {
  position: {
    top: string;
    left: string;
  };
};

const Wrapper = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: 181px;
  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img`
  width: 100%;
`;

const ripple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
    width: 1px;
    height: 1px;
  }
  99% {
    transform: scale(500);
    opacity: 0;
    width: 1px;
    height: 1px;
  }
  100% {
    transform: scale(0);
    width: 0px;
    height: 0px;
  }
`;

const Circle = styled.div<ICircle>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.buttons.click};
    border-radius: 50%;
    pointer-events: none;
    animation: ${ripple} 0.75s 1;
    top: ${({ position }) => position.top};
    left: ${({ position }) => position.left};
    width: 0px;
    height: 0px;
  }
`;

const Logo = ({ ...rest }: LogoProps) => {
  const [circle, setCircle] = useState<null | React.HTMLProps<ICircle>>(null);

  const createRipple = (x: number, y: number) => {
    setCircle(null);
    setTimeout(() => {
      setCircle(<Circle position={{ top: y + 'px', left: x + 'px' }} />);
    }, 1);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { offsetX, offsetY } = e.nativeEvent;
    createRipple(offsetX, offsetY);
  };

  return (
    <Link to="/">
      <Wrapper onClick={handleClick} {...rest} data-testid="logo-button">
        {circle}
        <Icon src={EcowayIcon} alt="ecoway logo icon" />
      </Wrapper>
    </Link>
  );
};

export default Logo;
