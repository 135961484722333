import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from '../../style';

const spin = keyframes`
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
`;

const Wrapper = styled.div<{
  size: number;
  color: string;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  z-index: ${({ theme }) => theme.zIndex.spinner};
`;

const Circle = styled.div<{
  color: string;
  borderWidth: number;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: ${({ borderWidth }) => `${borderWidth}px`} solid ${({ color }) => color};
  border-top: ${({ borderWidth }) => `${borderWidth}px`} solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  transform-origin: left top;
  animation: ${spin} 1s linear infinite;
`;

export type SpinnerProps = {
  size?: number;
  color?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Spinner = ({ size = 107, color = theme.colors.green, ...rest }: SpinnerProps) => {
  const borderWidth = size / 5.35;

  return (
    <Wrapper size={size} color={color} {...rest}>
      <Circle borderWidth={borderWidth} color={color} data-testid="spinner" />
    </Wrapper>
  );
};

export default Spinner;
