import { WalletAction } from '../../actions/walletActions/walletActions';
type WalletState = {
  address: string;
  tokenBalance: string;
  connectMethod: ConnectWalletMethod | null;
  error: string | null;
  status: ConnectWalletStatus;
  isConnectProcessActive: boolean;
  cooldown: string;
  stakedBalance: string;
};

export const initialState: WalletState = {
  address: '',
  connectMethod: null,
  tokenBalance: '0',
  error: null,
  status: 'DISCONNECTED',
  isConnectProcessActive: false,
  cooldown: '',
  stakedBalance: '0',
};

export const walletReducer = (
  state: WalletState = initialState,
  action: WalletAction,
): WalletState => {
  switch (action.type) {
    case 'CONNECT_WALLET_PROCESS_START':
      return { ...state, isConnectProcessActive: true };
    case 'CONNECT_WALLET_PROCESS_END':
      return { ...state, isConnectProcessActive: false };
    case 'CONNECT_WALLET_PENDING':
      return { ...state, connectMethod: action.payload, status: 'CONNECTING' };
    case 'CONNECT_WALLET_SUCCESS':
      return { ...state, ...action.payload, status: 'CONNECTED' };
    case 'CONNECT_WALLET_FAILURE':
      return {
        ...state,
        error: action.payload,
        status: 'DISCONNECTED',
        tokenBalance: '0',
        address: '',
      };
    case 'RESET_WALLET_STATE':
      return initialState;
    case 'FETCH_USER_BALANCE':
      return { ...state, tokenBalance: action.balance };
    case 'FETCH_USER_INFO_AFTER_STAKING':
      return {
        ...state,
        stakedBalance: action.stakedBalance,
        cooldown: action.cooldown,
      };
    default:
      return state;
  }
};
