import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: 0;
`;

export const Hamburger = styled.button`
  display: flex;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  outline: none;
  align-items: center;
`;

type IIcon = {
  isOpen: boolean;
};

export const IconBurger = styled.img<IIcon>`
  width: 15px;
  transform: ${({ isOpen }) => (isOpen ? css`rotate(-180deg)` : 'none')};
  transition: all 0.4s ease;
`;

export const Menu = styled(motion.ul)`
  position: absolute;
  top: 20.5px;
  right: -12px;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  padding: 16px;
  list-style: none;
  background-color: white;
  border-radius: 8px;
  transform-origin: top right;
  ${({ theme }) => theme.themeHelpers.breakpoints.down()} {
    right: -45px;
  }
`;

export const Li = styled(motion.li)`
  margin-bottom: 6px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.default};
`;

export const DisconnectLi = styled.li`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.red};
  margin-top: 14px;
  &:hover {
    color: ${({ theme }) => theme.colors.red50};
  }
`;

export const AdminPanelLi = styled.li`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.buttons.darkGreen};
  margin-top: 14px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.buttons.green};
  }
`;

export const Link = styled(NavLink)`
  transition: color 0.2s ease;
  font-family: Lexend Deca;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  &:hover {
    color: ${({ theme }) => theme.colors.default50};
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin: 0;
`;

export const TextGray = styled.p`
  color: ${({ theme }) => theme.colors.default};
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
`;

export const TextGreen = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;
