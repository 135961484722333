import { apiCall, apiCallWithAuthToken } from './config';

export const getUsersLogin = (walletAddress: string) =>
  apiCall.get<{ accessCode: string }>(`users/login?walletAddress=${walletAddress}`);

export const postUsersLogin = (walletAddress: string, signature: string) =>
  apiCall.post<JWTTokens>(`users/login`, { walletAddress, signature });

export const getUsersMe = () => apiCallWithAuthToken.get('users/me');

export const getAllUsers = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; users: AdminUsers[] }>(
    `/users${filter ? `?filter=${JSON.stringify(filter)}` : null}`,
  );

export const getUserById = (id: string) => apiCallWithAuthToken.get(`/users/${id}`);

export const getUserWithdrawals = (id: string, filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; withdrawals: Withdrawal[] }>(
    `/users/${id}/withdrawals${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const refreshAccessToken = (refreshToken: string) =>
  apiCall.post<JWTTokens>('/users/refresh-token', undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
