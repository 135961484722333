import styled from 'styled-components';
import LeafLeftImg from '../../assets/leaf_left.png';
import LeafRightImg from '../../assets/leaf_right.png';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  pointer-events: all;
  z-index: ${({ theme }) => theme.zIndex.background};
`;

const LeafLeft = styled.img`
  position: relative;
  top: 35%;
  width: 594px;
  z-index: inherit;
  color: red;
  ${({ theme }) => theme.themeHelpers.breakpoints.down('xxl')} {
    transform: scale(0.9);
    left: -2.5%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('xl')} {
    transform: scale(0.8);
    left: -7%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('lg')} {
    transform: scale(0.6);
    left: -15.5%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('md')} {
    left: -20%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('sm')} {
    display: none;
  }
`;

const LeafRight = styled.img`
  position: absolute;
  top: -10%;
  right: -10%;
  width: 789px;
  z-index: inherit;
  ${({ theme }) => theme.themeHelpers.breakpoints.down('xxl')} {
    transform: scale(0.9);
    right: -18%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('xl')} {
    transform: scale(0.8);
    right: -15%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('lg')} {
    transform: scale(0.6);
    right: -25%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('sm')} {
    right: -28%;
    top: 30%;
  }
`;

const BackgroundTemplate = () => {
  return (
    <Wrapper>
      <LeafLeft src={LeafLeftImg} alt="left leaf" />
      <LeafRight src={LeafRightImg} alt="left leaf" />
    </Wrapper>
  );
};

export default BackgroundTemplate;
