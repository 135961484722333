import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { alertActions } from '../../store';
import { routes } from '../../utils';
import Spinner from '../Spinner/Spinner';

type PrivateRouteProps = RouteProps & {
  redirectTo?: string;
  admin?: boolean;
  walletRequired?: boolean;
};

export const checkIfContainsRole = (roles: UserRole[], type: string) => {
  let isWalletConnectedWhenRequired = true;
  const userType = roles.map((i: UserRole) => {
    if (type === 'ADMIN') {
      if (Object.values(i).includes(type)) {
        return true;
      }
    } else if (type === 'USER') {
      if (Object.values(i).includes(type) || 'ADMIN' || 'SUPER_OWNER') {
        return true;
      }
    } else if (type === 'SUPER_OWNER') {
      if (Object.values(i).includes(type)) {
        return true;
      }
    }
    return false;
  });

  return userType.includes(true) && isWalletConnectedWhenRequired;
};

const PrivateRoute = ({
  component: Component,
  redirectTo = routes.home,
  admin,
  walletRequired = false,
  ...props
}: PrivateRouteProps) => {
  const user = useSelector((store: RootStore) => store.users);
  const walletStatus = useSelector((store: RootStore) => store.wallet.status);
  const isWalletConnected = walletStatus === 'CONNECTED';
  const dispatch = useDispatch();

  const {
    loading,
    isAuth,
    data: { roles },
  } = user;

  const isAccess = useMemo(
    () =>
      admin
        ? checkIfContainsRole(roles, 'ADMIN') || checkIfContainsRole(roles, 'SUPER_OWNER')
        : checkIfContainsRole(roles, 'USER'),
    [isAuth, roles, admin, isWalletConnected, walletRequired],
  );

  const isThereAlertAboutWallet = useSelector((store: RootStore) =>
    store.alerts.some((alert) => alert.heading === 'Your wallet is not connected.'),
  );

  if (!Component) return null;

  if (walletRequired && !isWalletConnected) {
    if (!isThereAlertAboutWallet) {
      dispatch(
        alertActions.warning({
          heading: 'Your wallet is not connected.',
          message: 'Connecting the wallet is required to complete the transaction',
        }),
      );
    }

    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <Route
        {...props}
        render={(routeProps) =>
          loading ? (
            <Spinner />
          ) : isAccess ? (
            <Component {...routeProps} />
          ) : (
            <Redirect
              to={{ pathname: redirectTo, state: { from: routeProps.location.pathname } }}
            />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
