import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../../store';
import { fadeAnimation } from '../../../utils';
import ExitButton from '../../../assets/exit.svg';
import * as S from './styled';
import { useDetectOutsideClick } from '../../../hooks';

export type AlertProps = AlertObject;

const Alert = ({ heading, message, id, delay, type }: AlertProps) => {
  const dispatch = useDispatch();

  const removeAlert = () => dispatch(alertActions.removeAlert(id));
  const ref = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(ref, removeAlert);

  useEffect(() => {
    const timeoutId = setTimeout(removeAlert, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <S.AlertWrapper
      className="alert"
      {...fadeAnimation}
      layout
      type={type}
      data-testid="alertWrapper"
      ref={ref}
    >
      <S.Heading type={type}>{heading}</S.Heading>
      <S.ExitButton onClick={removeAlert} data-testid="closeAlertBtn">
        <S.ExitImg src={ExitButton} alt="exit button" />
      </S.ExitButton>
      <S.Message>{message}</S.Message>
    </S.AlertWrapper>
  );
};

export default Alert;
