import React from 'react';
import styled from 'styled-components';

export interface LabelStakingProps extends React.ComponentPropsWithoutRef<'div'> {
  topText: string;
  bottomText: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
`;

const TopText = styled.span`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #707070;
`;

const BottomText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #2b8f27;
`;

const LabelStaking: React.FC<LabelStakingProps> = ({ topText, bottomText, ...rest }) => {
  return (
    <Container {...rest}>
      <TopText>{topText}</TopText>
      <BottomText>{bottomText}</BottomText>
    </Container>
  );
};

export default LabelStaking;
