import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import * as S from './Modal.styled';
import { useDetectOutsideClick } from '../../hooks';
import { AnimatePresence } from 'framer-motion';
import { fadeAnimation } from '../../utils';
import ExitIcon from '../../assets/exit_icon_grey.svg';

export interface IModalProps {
  size?: 'normal' | 'small' | 'large';
}
export interface IModal extends React.HtmlHTMLAttributes<HTMLDivElement>, IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  closeModal?: () => void;
  header?: string;
}

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal');
document.body.appendChild(modalRoot);

const alerts = document.getElementsByClassName('alert');

const Modal = ({ children, isOpen, closeModal, size = 'normal', header }: IModal) => {
  const modalRef = useRef<HTMLDivElement>(null);
  let el = document.getElementById('modal');

  if (!el) {
    el = document.createElement('div');
    el.setAttribute('id', 'modal');
    document.body.appendChild(el);
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const handleOutsideClick = () => {
    document.body.style.overflow = 'auto';
    if (isOpen && closeModal) {
      closeModal();
    }
  };

  useDetectOutsideClick(modalRef, handleOutsideClick, alerts);
  return createPortal(
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <S.Background {...fadeAnimation} data-testid="testDiv">
          <S.Container size={size} ref={modalRef}>
            {closeModal && (
              <S.ExitButton onClick={handleOutsideClick}>
                <S.ExitIcon src={ExitIcon} alt="exit icon" />
              </S.ExitButton>
            )}
            {header && <S.Heading>{header}</S.Heading>}
            {children}
          </S.Container>
        </S.Background>
      )}
    </AnimatePresence>,
    el,
  );
};

export default Modal;
