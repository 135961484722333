import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDetectOutsideClick } from '../../hooks';
import arrow from './arrow.svg';
import checked from './checked.svg';
import unchecked from './unchecked.svg';
import hexRgb from 'hex-rgb';
import _isEqual from 'lodash/isEqual';
export type Option = {
  name: string;
  value: string | number;
  id?: string;
};

export interface InputSelectProps {
  options: Option[];
  success?: string | boolean;
  error?: string | boolean;
  caption?: string;
  title?: string;
  multiple?: boolean;
  onChange?: (l: Option[]) => void;
  adminInput?: boolean;
  defaultValue?: Option;
}

const Container = styled.div<{
  opened: boolean;
  error: boolean;
  success: boolean;
  adminInput: boolean;
  selected: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 14px;
  background: ${({ success, error, theme, adminInput }) => {
    if (error) return theme.colors.alerts.error;
    if (success) return theme.colors.labels.lightGreen;
    return adminInput
      ? hexRgb(theme.colors.green, { format: 'css', alpha: 0.1 })
      : theme.colors.white;
  }};

  border: 1px solid
    ${({ success, error, theme, adminInput }) => {
      if (error) return theme.colors.alerts.error;
      if (success) return theme.colors.labels.lightGreen;
      return adminInput
        ? hexRgb(theme.colors.green, { format: 'css', alpha: 0.1 })
        : theme.colors.white;
    }};
  border-radius: 6px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  box-sizing: border-box;
  height: 36px;
  color: ${({ theme, opened, selected }) =>
    opened || selected ? theme.colors.default : '#858F9F'};
  cursor: pointer;
  transition: 0.3s;

  ${({ opened, theme }) => (opened ? `border: 1px solid ${theme.colors.buttons.hover};` : '')}

  &:hover {
    background: #f8f8f8;
  }
  & span {
    margin-right: 8px;
  }
`;

const List = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0px 1px 8px 2px rgb(0 0 0 / 10%), 1px 1px 3px rgb(0 0 0 / 8%);
  border-radius: 6px;
  transform: translateY(100%);
  z-index: 2;
`;

const Item = styled.div<React.HTMLProps<HTMLDivElement> & { selected: boolean }>`
  padding: 12px 24px;
  background: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.colors.default};
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 6px;
  transition: all 50ms ease-out;

  &:hover {
    background: #f8f8f8;
    color: ${({ theme }) => theme.colors.default};
    cursor: pointer;
  }
  ${({ selected, theme }) => (selected ? `color: ${theme.colors.buttons.hover}` : '')}
`;

const Checkbox = styled.img.attrs<{ selected: boolean }>(({ selected, ...props }) => ({
  ...props,
  src: selected ? checked : unchecked,
}))<{ selected: boolean }>`
  margin-right: 10px;
  height: 18px;
  width: 18px;
`;

const BottomMessage = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  margin: 4px 0 0 4px;
`;
const CaptionMessage = styled(BottomMessage)`
  color: ${({ theme }) => theme.colors.default};
`;
const ErrorMessage = styled(BottomMessage)`
  color: ${({ theme }) => theme.colors.alerts.error};
`;
const SuccessMessage = styled(BottomMessage)`
  color: ${({ theme }) => theme.colors.labels.border};
`;
const InputSelect = ({
  options,
  onChange,
  title,
  defaultValue,
  caption,
  success,
  error,
  multiple = false,
  adminInput = false,
  ...rest
}: Omit<React.HTMLProps<HTMLDivElement>, 'defaultValue' | 'children' | 'as' | 'onChange'> &
  InputSelectProps) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState<Option[]>(defaultValue ? [defaultValue] : []);

  const ref = useRef<HTMLDivElement>(null);
  useDetectOutsideClick(ref, () => setOpened(false));

  useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (defaultValue) setSelected([defaultValue]);
  }, [defaultValue]);

  const selectOption = (i: number) => {
    if (selected.indexOf(options[i]) >= 0) return;
    if (multiple) {
      setSelected([...selected, options[i]]);
    } else {
      setSelected([options[i]]);
    }
  };

  const unselectOption = (i: number) => {
    if (selected.indexOf(options[i]) < 0) return;
    const newArray = selected.filter((o) => o !== options[i]);
    if (newArray.length !== 0) {
      setSelected(newArray);
    } else {
      if (defaultValue) {
        setSelected([defaultValue]);
      } else {
        setSelected([]);
      }
    }
  };

  return (
    <div>
      <Container
        onClick={() => setOpened(!opened)}
        opened={opened}
        selected={!multiple && selected.length === 1}
        {...rest}
        ref={ref}
        adminInput={adminInput}
        success={Boolean(success)}
        error={Boolean(error)}
      >
        <span>
          {title}
          {!multiple && selected.length === 1 ? selected[0].name : ''}
        </span>
        <img src={arrow} alt="Click to open the list" />
        {opened && (
          <List
            onClick={(e) => {
              if (multiple) e.stopPropagation();
            }}
          >
            {options.map((elem, i) => (
              <Item
                key={i}
                selected={selected.some((el) => _isEqual(el, elem))}
                onClick={() => (selected.indexOf(elem) >= 0 ? unselectOption(i) : selectOption(i))}
              >
                {multiple ? <Checkbox selected={selected.indexOf(elem) >= 0} /> : null}
                {elem.name}
              </Item>
            ))}
          </List>
        )}
      </Container>
      {success && typeof success !== 'boolean' && <SuccessMessage>{success}</SuccessMessage>}
      {error && typeof error !== 'boolean' && <ErrorMessage>{error}</ErrorMessage>}
      {caption && <CaptionMessage>{caption}</CaptionMessage>}
    </div>
  );
};

export default InputSelect;
