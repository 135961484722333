export enum AlertActionTypes {
  SUCCESS_ALERT = 'SUCCESS_ALERT',
  WARNING_ALERT = 'WARNING_ALERT',
  ERROR_ALERT = 'ERROR_ALERT',
  INFO_ALERT = 'INFO_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

const DEFAULT_DELAY = 5000;
let id = 0;

type AlertActionType<T extends AlertActionTypes> = {
  type: T;
  payload: AlertObject;
};

type AlertActionCreatorType<T extends AlertActionTypes> = (params: {
  heading?: string;
  message: string;
  delay?: number;
}) => AlertActionType<T>;

export const warning: AlertActionCreatorType<AlertActionTypes.WARNING_ALERT> = ({
  heading = 'Warning!',
  message,
  delay = DEFAULT_DELAY,
}) => ({
  type: AlertActionTypes.WARNING_ALERT,
  payload: {
    type: 'WARNING',
    id: id++,
    heading,
    message,
    delay,
  },
});

export const success: AlertActionCreatorType<AlertActionTypes.SUCCESS_ALERT> = ({
  heading = 'Well done!',
  message,
  delay = DEFAULT_DELAY,
}) => ({
  type: AlertActionTypes.SUCCESS_ALERT,
  payload: {
    type: 'SUCCESS',
    id: id++,
    heading,
    message,
    delay,
  },
});

export const error: AlertActionCreatorType<AlertActionTypes.ERROR_ALERT> = ({
  heading = 'Oh snap!',
  message,
  delay = DEFAULT_DELAY,
}) => ({
  type: AlertActionTypes.ERROR_ALERT,
  payload: {
    type: 'ERROR',
    id: id++,
    heading,
    message,
    delay,
  },
});

export const info: AlertActionCreatorType<AlertActionTypes.INFO_ALERT> = ({
  heading = 'Hi there!',
  message,
  delay = DEFAULT_DELAY,
}) => ({
  type: AlertActionTypes.INFO_ALERT,
  payload: {
    type: 'INFO',
    id: id++,
    heading,
    message,
    delay,
  },
});

type RemoveAlertActionType = { type: AlertActionTypes.REMOVE_ALERT; payload: number };

export const removeAlert = (id: number): RemoveAlertActionType => ({
  type: AlertActionTypes.REMOVE_ALERT,
  payload: id,
});

export type AlertAction =
  | AlertActionType<AlertActionTypes.WARNING_ALERT>
  | AlertActionType<AlertActionTypes.SUCCESS_ALERT>
  | AlertActionType<AlertActionTypes.ERROR_ALERT>
  | AlertActionType<AlertActionTypes.INFO_ALERT>
  | RemoveAlertActionType;
