import { Dispatch } from 'redux';
import { dev } from '../config';
import { alertActions } from '../store';

export const addBscNetworkToMetamask = async (
  dispatch: Dispatch,
  windowObj: Window & typeof globalThis = window,
) => {
  try {
    if (windowObj.ethereum) {
      if (dev()) {
        return await windowObj.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x61',
              chainName: 'Binance Testnet',
              nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://data-seed-prebsc-2-s1.binance.org:8545/'],
              blockExplorerUrls: ['https://testnet.bscscan.com'],
            },
          ],
        });
      } else {
        return await windowObj.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x38',
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed3.defibit.io/'],
              blockExplorerUrls: ['https://bscscan.com'],
            },
          ],
        });
      }
    }
  } catch (e) {
    dispatch(
      alertActions.warning({
        heading: 'Woops... Invalid network.',
        message: 'Please set up Binance Smart Chain network.',
      }),
    );
  }
};
