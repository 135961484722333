import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const loggerMiddleware = createLogger();

const middleware: Middleware[] = [thunk];

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  middleware.push(loggerMiddleware);
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;
}

export const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));
