import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AlertWrapper = styled(motion.div)<{ type: AlertType }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${({ type, theme }) => {
    switch (type) {
      case 'SUCCESS':
        return theme.colors.alerts.success;
      case 'WARNING':
        return theme.colors.alerts.warning;
      case 'ERROR':
        return theme.colors.alerts.error;
      default:
        return theme.colors.alerts.info;
    }
  }};
  width: 438px;
  margin-top: 10px;
  padding: 13px 24px 27px 24px;
  overflow: hidden;
  pointer-events: all;

  @media (max-width: 640px) {
    display: block;
    max-width: 500px;
    width: calc(100% - 30px);
    min-width: auto;
    margin: 10px auto;
  }
`;

export const Heading = styled.p<{ type: AlertType }>`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.035em;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  margin: 0;
  margin-bottom: 6px;
  margin-right: 12px;
`;

export const Message = styled.p`
  font-family: Lexend Deca;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  margin: 0;

  @media (min-width: 640px) {
    max-width: 250px;
  }
`;

export const ExitImg = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
`;

export const ExitButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: scale 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
