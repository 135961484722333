import React, { useState, useRef } from 'react';
import * as S from './DropdownMenu.styled';
import ChevronIcon from '../../assets/chevron.svg';
import { AnimatePresence } from 'framer-motion';
import { useDetectOutsideClick } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions, walletActions } from '../../store';
import { useHistory } from 'react-router';
import { routes } from '../../utils';
import { checkIfContainsRole } from '../PrivateRoute/PrivateRoute';
export interface IDropdownMenuProps {
  to: string;
  title: string;
}

export interface IDropdownMenu extends React.HTMLAttributes<HTMLDivElement> {
  menuItems: IDropdownMenuProps[];
  userIdentifier: string;
  gotNickName: boolean | string;
}

const DropdownMenu = ({ menuItems, gotNickName, userIdentifier, ...rest }: IDropdownMenu) => {
  const [isOpen, setIsOpen] = useState(false);
  const naviRef = useRef<HTMLDivElement>(null);
  const collapseMenu = () => setIsOpen(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((store: RootStore) => store.users.data);

  const handleOutsideClick = () => {
    if (isOpen) {
      collapseMenu();
    }
  };

  const handleDisconnect = () => {
    dispatch(walletActions.resetWalletState());
    dispatch(usersActions.logoutUser());
  };

  const handleAdminPanelClick = () => {
    history.push(routes.admin.self);
  };

  useDetectOutsideClick(naviRef, handleOutsideClick);
  return (
    <S.Container ref={naviRef} {...rest}>
      <S.Hamburger
        type="button"
        onClick={() => setIsOpen((prevState) => !prevState)}
        data-testid="burger-button"
      >
        <S.TextGray className="mr-5">
          Hey,
          <S.TextGreen>
            {' '}
            {gotNickName
              ? `${gotNickName?.toString().slice(0, 15)}${
                  gotNickName?.toString().length > 15 ? '...' : ''
                }`
              : userIdentifier.slice(0, 9) + '...'}
          </S.TextGreen>
        </S.TextGray>
        <S.IconBurger isOpen={isOpen} src={ChevronIcon} alt="Menu icon" />
      </S.Hamburger>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <>
            <S.Menu
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              data-testid="dropdown-menu"
              variants={{
                open: { opacity: 1, scale: 1 },
                collapsed: { opacity: 0, scale: 0 },
              }}
              transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              {menuItems.map((el, index) => (
                <S.Li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  key={index}
                  onClick={collapseMenu}
                  data-testid="dropdown-li"
                >
                  <S.Link to={el.to}>{el.title}</S.Link>
                </S.Li>
              ))}
              {checkIfContainsRole(userData.roles, 'ADMIN') ||
              checkIfContainsRole(userData.roles, 'SUPER_OWNER') ? (
                <S.AdminPanelLi onClick={handleAdminPanelClick}>Admin panel</S.AdminPanelLi>
              ) : null}
              <S.DisconnectLi onClick={handleDisconnect}>Disconnect</S.DisconnectLi>
            </S.Menu>
          </>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default DropdownMenu;
