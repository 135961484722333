import { useCallback, useState } from 'react';
import PaginatorComponent from '../components/Paginator/Paginator';

const usePaginator = (_limit = 15) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(_limit);
  const [elements, setElements] = useState(0);

  const pages = Math.ceil(elements / limit);

  const Paginator = useCallback(
    () => <PaginatorComponent onPageChange={setPage} pagesNumber={pages} page={page} />,
    [elements, limit, page, pages],
  );

  return {
    Paginator,
    page,
    setPage,
    limit,
    setLimit,
    setElements,
    elements,
  };
};

export default usePaginator;
