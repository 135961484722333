const BREAKPOINTS = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px', // default
  xl: '1200px',
  xxl: '1400px',
} as const;

function up(breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl') {
  const size = breakpoint ? BREAKPOINTS[breakpoint] : BREAKPOINTS['lg'];
  return `@media screen and (min-width: ${size})`;
}

function down(breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl') {
  const size = breakpoint ? BREAKPOINTS[breakpoint] : BREAKPOINTS['lg'];
  return `@media screen and (max-width: ${size})`;
}

function between(
  breakpoint1: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
  breakpoint2?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
) {
  const size1 = BREAKPOINTS[breakpoint1] || BREAKPOINTS['lg'];
  const size2 = breakpoint2 ? BREAKPOINTS[breakpoint2] : BREAKPOINTS['lg'];
  if (breakpoint2) {
    return `@media screen and (min-width: ${size1}) and (max-width: ${size2})`;
  }
  return `@media screen and (max-width: ${size1})`;
}

function spacing(...args: Array<number | string>) {
  const sizes = [0, 4, 8, 16, 32, 64];
  let pxs: string[] = [];

  args.forEach((idx) => {
    if (typeof idx === 'number') {
      if (sizes[idx] !== undefined) {
        pxs.push(`${sizes[idx]}px`);
      }
    } else if (typeof idx === 'string') {
      pxs.push(idx);
    }
  });

  return pxs.join(' ');
}

function only(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl') {
  switch (breakpoint) {
    case 'xs':
      return between('xs', 'sm');
    case 'sm':
      return between('sm', 'md');
    case 'lg':
      return between('lg', 'xl');
    case 'xl':
      return between('xl', 'xxl');
    case 'xxl':
      return between('xxl');
    default:
      return between('md', 'lg');
  }
}

const themeHelpers = {
  breakpoints: {
    up,
    down,
    between,
    only,
  },
  spacing,
};

const theme = {
  colors: {
    alerts: {
      success: '#449B23',
      warning: '#F49200',
      error: '#F4002C',
      info: '#003E62',
    },
    buttons: {
      green: '#449B23',
      gray: '#EEEEEE',
      grayText: '#B7B7B7',
      darkGreen: '#306C19',
      hover: '#367c1c',
      click: 'rgba(255,255,255, 0.3)',
    },
    labels: {
      green: '#E8F4E6',
      lightGreen: '#F3F9F2',
      border: '#44633F',
    },
    input: {
      gray: '#F8F8F8',
    },
    white: '#FFFFFF',
    white50: 'rgba(255, 255, 255, 0.5)',
    default: '#707070',
    default50: 'rgba(112, 112, 112, 0.5)',
    blue: '#2CB1EA',
    green: '#2B8F27',
    orange: '#F09A12',
    red: '#DB0000',
    red50: 'rgba(219, 0, 0, 0.5)',
    tooltip: {
      bgColor: '#0E2107',
      brdrColor: 'rgba(233, 230, 239, 0.05)',
    },
    black: '#000',
  },
  typography: {
    fontFamily: 'Lexend Deca',
    fontWeight: '400',
    h1: '4rem',
    h2: '3rem',
    h3: '2rem',
    h4: '1.4rem',
    h5: '1.2rem',
    h6: '0.8rem',
    body: '1rem',
  },
  zIndex: {
    dropdown: 900,
    modal: 1000,
    tooltip: 1100,
    spinner: 1200,
    background: -1,
    animatedCoins: 99,
    centerCard: 100,
    centerCardText: 101,
  },
  themeHelpers,
} as const;

export default theme;
