import { HTMLAttributes } from 'react';
import * as S from './styled';

type HamburgerProps = {
  size?: number;
} & HTMLAttributes<HTMLDivElement>;

const Hamburger = ({ size = 22, ...rest }: HamburgerProps) => {
  return (
    <S.HamburgerBtn size={size} {...rest}>
      <S.TopStripe />
      <S.CenterStripe />
      <S.BottomStripe />
    </S.HamburgerBtn>
  );
};

export default Hamburger;
