import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 6px 12px 1px rgba(7, 7, 7, 0.1);
  z-index: 10;
  position: relative;
  top: 0;
  left: 0;
  ${({ theme }) => theme.themeHelpers.breakpoints.down('md')} {
    z-index: initial;
  }
`;

export const HamburgerWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  width: 56px;
  height: 56px;
  margin-right: 24px;
  padding: 0;
  cursor: pointer;
`;

export const Disconnect = styled.button`
  background-color: transparent;
  border: none;
  transition: opacity 0.3s;
  cursor: pointer;
  margin-right: 24px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.red};
  &:hover {
    opacity: 0.7;
  }
`;
