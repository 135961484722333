import { apiCall, apiCallWithAuthToken } from './config';

export const getStakingUsersCount = () => apiCall.get<{ count: number }>('/stakings/users-count');

export const getTotalCoinsCount = () =>
  apiCall.get<{ count: number }>('/stakings/total-coins-count');

export const getEarnings = () =>
  apiCallWithAuthToken.get<{ earnings: string }>('/stakings/user/earnings');

export const getStakings = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; stakings: Stakings[] }>(
    `stakings${filter ? `?filter=${JSON.stringify(filter)}` : null}`,
  );

export const getStakingsDaysSummary = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; daySummaries: StakingsDaysSummary[] }>(
    `stakings/days-summary${filter ? `?filter=${JSON.stringify(filter)}` : null}`,
  );

export const getStakingGainsByUserId = (id: string, filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; stakingGains: StakingGains[] }>(
    `/staking-gains/user/${id}${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const setAmount = (id: number, amount: string) =>
  apiCallWithAuthToken.patch(`staking-gains/amount/${id}`, { amount: amount });

export const getStakingsDay = (day: string, filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; stakings: Stakings[] }>(
    `/stakings/day/${day}${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const getStakingGainsDaySummaries = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; data: StakingGainsByDay[] }>(
    `/staking-gains-by-day/${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const getAllStakingGains = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; stakingGains: StakingGains[] }>(
    `/staking-gains${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const getAllStakingDaySummary = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; daySummaries: any[] }>(
    `/staking-gains/days-summary${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const getStakingGainsByDay = (day: string, filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; stakingGains: StakingGains[] }>(
    `/staking-gains/day/${day}${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const acceptStakingGains = (day: string) =>
  apiCallWithAuthToken.patch(`staking-gains/accept-many/day/${day}`);

export const getStakingGainsSummary = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; data: StakingGainsByDay[] }>(
    `/staking-gains-by-day${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );
