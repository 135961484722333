import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import _uniqueId from 'lodash/uniqueId';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  children?: React.ReactNode;
  error?: boolean | string;
  unselectAll?: boolean;
  description?: boolean | string;
  disabled?: boolean;
  disabledMargin?: boolean;
}

const tickLeft = keyframes`
  from {
    width: 0;
  }
  to {
    width: 4.7px;
  }
`;

const tickRight = keyframes`
  from {
    height: 0;
    top: 8.7px;
  }
  to {
    height: 7.7px;
    top: 0.8px;
  }
`;

const tickMinus = keyframes`
  from {
    height: 0;
    top: 8.7px;
  }
  to {
    height: 10px;
    top: -5px;
  }
`;

const ContainerCheckbox = styled.div<{
  error: boolean | string;
  unselectAll: boolean;
  description: boolean | string;
  disabled: boolean;
  disabledMargin: boolean;
}>`
  position: relative;
  letter-spacing: 0.3px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: ${({ theme, error }) =>
        error ? theme.colors.alerts.error : theme.colors.buttons.hover};
      border-color: ${({ theme, error }) =>
        error ? theme.colors.alerts.error : theme.colors.buttons.hover};
      .tick {
        transform: ${({ unselectAll }) => (unselectAll ? 'rotate(90deg)' : 'rotate(38deg)')};
        &::after {
          width: 2px;
          height: ${({ unselectAll }) => (unselectAll ? '10px' : '7.7px')};
          left: ${({ unselectAll }) => (unselectAll ? '12px' : '10px')};
          top: ${({ unselectAll }) => (unselectAll ? '-5px' : '0.8px')};
          animation: ${({ unselectAll }) => (unselectAll ? tickMinus : tickRight)} 0.1s ease-in-out
            0.1s;
          animation-fill-mode: both;
        }

        &::before {
          width: 4.7px;
          height: 2px;
          left: 7px;
          top: 6.5px;
          animation: ${tickLeft} 0.15s ease-in-out;
          opacity: ${({ unselectAll }) => (unselectAll ? '0' : '1')};
        }
      }
      .tick {
        &::after,
        &::before {
          position: absolute;
          content: '';
          background-color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }

  .checkmark {
    transition: all 0.2s ease-out;
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16.7px;
    border: 2px solid
      ${({ theme, error }) => (error ? theme.colors.alerts.error : theme.colors.default)};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  label {
    display: block;
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    color: ${({ theme }) => theme.colors.default};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 17px;
    padding-left: ${({ disabledMargin }) => (disabledMargin ? '16px' : '28px')};
    min-height: 19px;
    margin-bottom: ${({ error, description, disabledMargin }) =>
      disabledMargin ? '0' : error || description ? '0' : '20px'};
    line-height: 22px;
    text-align: left;
    position: relative;
    a {
      color: inherit;
      text-decoration: underline;
    }
    opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};
  }

  &:focus-within {
    input {
      &:checked ~ .checkmark {
        border-color: ${({ theme }) => theme.colors.default50};
      }
    }
  }
`;

const ErrorMessage = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.alerts.error};
  margin-left: 28px;
`;

const DescriptionMessage = styled(ErrorMessage)`
  color: ${({ theme }) => theme.colors.default};
`;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      error = false,
      name,
      children,
      className,
      unselectAll = false,
      description = false,
      disabled = false,
      disabledMargin = false,
      ...rest
    },
    ref,
  ) => {
    const [id] = useState(_uniqueId('input-'));
    return (
      <ContainerCheckbox
        error={error}
        className={className}
        unselectAll={unselectAll}
        description={description}
        disabled={disabled}
        disabledMargin={disabledMargin}
      >
        <label htmlFor={id}>
          {children}
          <input
            name={name}
            type="checkbox"
            id={id}
            ref={ref}
            {...rest}
            data-testid="checkbox"
            disabled={disabled}
          />
          <div className="checkmark" data-testid="checkmark">
            <div className="tick" data-testid="tick" />
          </div>
        </label>
        {typeof error === 'string' && <ErrorMessage>{error}</ErrorMessage>}
        {typeof description === 'string' && <DescriptionMessage>{description}</DescriptionMessage>}
      </ContainerCheckbox>
    );
  },
);

export default Checkbox;
