import { apiCallWithAuthToken } from './config';

export const getLotteryWins = () => apiCallWithAuthToken.get(`/lottery-wins`);

export const getLotteryWinsByUserId = (id: string, filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; lotteryWins: LotteryWins[] }>(
    `/lottery-wins/user/${id}${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const setAmount = (id: number, amount: string) =>
  apiCallWithAuthToken.patch(`lottery-wins/won-amount/${id}`, { amount: amount });

export const getLatestLotteryWins = () =>
  apiCallWithAuthToken.get<LatestLotteryWins>('/lotteries/latest/winners');

export const getAllLotteries = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; lotteries: any[] }>(
    `/lotteries${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const getAllLotteryWins = (filter?: ApiFilter) =>
  apiCallWithAuthToken.get<{ count: number; lotteryWins: LotteryWins[] }>(
    `/lottery-wins${filter ? `?filter=${JSON.stringify(filter)}` : ''}`,
  );

export const acceptLotteryWins = (id: string) =>
  apiCallWithAuthToken.patch(`lottery-wins/accept/lottery/${id}`);

export const getLotteriesMe = () =>
  apiCallWithAuthToken.get<{ count: number; lotteryWins: LotteryWins[] }>(`/lottery-wins/me`);
