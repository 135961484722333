import styled, { keyframes } from 'styled-components';
import CoinIcon from '../../assets/coin.svg';
const Wrapper = styled.div<ICoin>`
  display: block;
  position: absolute;
  top: ${({ posY }) => posY};
  right: ${({ posX }) => posX};
  width: 75px;
  z-index: ${({ theme }) => theme.zIndex.animatedCoins};
`;

export type ICoin = {
  posX: string;
  posY: string;
} & React.HTMLAttributes<HTMLDivElement>;

const flyingCoin = keyframes`
  30% {opacity:1}
  100% { transform: translateY(-200px); opacity: 0;}
`;

const Coin = styled.img<{ startPosX: string }>`
  position: absolute;
  width: 16px;
  top: 200px;
  right: ${({ startPosX }) => startPosX};
  animation-name: ${flyingCoin};
  animation-direction: normal;
  animation-iteration-count: infinite;
  &:nth-child(1) {
    animation-duration: 4s;
  }
  &:nth-child(2) {
    animation-duration: 3s;
  }
  &:nth-child(3) {
    animation-duration: 2s;
  }
`;

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

let coinsStartPositions: any = [];
let coinsStartPositionsY: any = [];
for (let i = 0; i < 6; i++) {
  coinsStartPositions.push(getRandomInt(0, 180));
  coinsStartPositionsY.push(getRandomInt(0, 100));
}
const AnimatedCoins = ({ posX, posY, ...rest }: ICoin) => {
  return (
    <Wrapper posX={posX} posY={posY} {...rest}>
      <Coin src={CoinIcon} startPosX={'8px'} />
      <Coin src={CoinIcon} startPosX={'37.5px'} />
      <Coin src={CoinIcon} startPosX={'67px'} />
    </Wrapper>
  );
};

export default AnimatedCoins;
