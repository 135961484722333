import React from 'react';
import styled from 'styled-components';
import './Icon.css';

export type IconName =
  | 'feather-alert-octagon'
  | 'feather-alert-circle'
  | 'feather-activity'
  | 'feather-alert-triangle'
  | 'feather-align-center'
  | 'feather-airplay'
  | 'feather-align-justify'
  | 'feather-align-left'
  | 'feather-align-right'
  | 'feather-arrow-down-left'
  | 'feather-arrow-down-right'
  | 'feather-anchor'
  | 'feather-aperture'
  | 'feather-arrow-left'
  | 'feather-arrow-right'
  | 'feather-arrow-down'
  | 'feather-arrow-up-left'
  | 'feather-arrow-up-right'
  | 'feather-arrow-up'
  | 'feather-award'
  | 'feather-bar-chart'
  | 'feather-at-sign'
  | 'feather-bar-chart-2'
  | 'feather-battery-charging'
  | 'feather-bell-off'
  | 'feather-battery'
  | 'feather-bluetooth'
  | 'feather-bell'
  | 'feather-book'
  | 'feather-briefcase'
  | 'feather-camera-off'
  | 'feather-calendar'
  | 'feather-bookmark'
  | 'feather-box'
  | 'feather-camera'
  | 'feather-check-circle'
  | 'feather-check'
  | 'feather-check-square'
  | 'feather-cast'
  | 'feather-chevron-down'
  | 'feather-chevron-left'
  | 'feather-chevron-right'
  | 'feather-chevron-up'
  | 'feather-chevrons-down'
  | 'feather-chevrons-right'
  | 'feather-chevrons-up'
  | 'feather-chevrons-left'
  | 'feather-circle'
  | 'feather-clipboard'
  | 'feather-chrome'
  | 'feather-clock'
  | 'feather-cloud-lightning'
  | 'feather-cloud-drizzle'
  | 'feather-cloud-rain'
  | 'feather-cloud-off'
  | 'feather-codepen'
  | 'feather-cloud-snow'
  | 'feather-compass'
  | 'feather-copy'
  | 'feather-corner-down-right'
  | 'feather-corner-down-left'
  | 'feather-corner-left-down'
  | 'feather-corner-left-up'
  | 'feather-corner-up-left'
  | 'feather-corner-up-right'
  | 'feather-corner-right-down'
  | 'feather-corner-right-up'
  | 'feather-cpu'
  | 'feather-credit-card'
  | 'feather-crosshair'
  | 'feather-disc'
  | 'feather-delete'
  | 'feather-download-cloud'
  | 'feather-download'
  | 'feather-droplet'
  | 'feather-edit-'
  | 'feather-edit'
  | 'feather-edit-1'
  | 'feather-external-link'
  | 'feather-eye'
  | 'feather-feather'
  | 'feather-facebook'
  | 'feather-file-minus'
  | 'feather-eye-off'
  | 'feather-fast-forward'
  | 'feather-file-text'
  | 'feather-film'
  | 'feather-x'
  | 'feather-file'
  | 'feather-file-plus'
  | 'feather-folder'
  | 'feather-filter'
  | 'feather-flag'
  | 'feather-globe'
  | 'feather-grid'
  | 'feather-heart'
  | 'feather-home'
  | 'feather-github'
  | 'feather-image'
  | 'feather-inbox'
  | 'feather-layers'
  | 'feather-info'
  | 'feather-instagram'
  | 'feather-layout'
  | 'feather-link-'
  | 'feather-life-buoy'
  | 'feather-link'
  | 'feather-log-in'
  | 'feather-list'
  | 'feather-lock'
  | 'feather-log-out'
  | 'feather-loader'
  | 'feather-mail'
  | 'feather-maximize-'
  | 'feather-map'
  | 'feather-map-pin'
  | 'feather-menu'
  | 'feather-message-circle'
  | 'feather-message-square'
  | 'feather-minimize-'
  | 'feather-mic-off'
  | 'feather-minus-circle'
  | 'feather-mic'
  | 'feather-minus-square'
  | 'feather-minus'
  | 'feather-moon'
  | 'feather-monitor'
  | 'feather-more-vertical'
  | 'feather-more-horizontal'
  | 'feather-move'
  | 'feather-music'
  | 'feather-navigation-'
  | 'feather-navigation'
  | 'feather-octagon'
  | 'feather-package'
  | 'feather-pause-circle'
  | 'feather-pause'
  | 'feather-percent'
  | 'feather-phone-call'
  | 'feather-phone-forwarded'
  | 'feather-phone-missed'
  | 'feather-phone-off'
  | 'feather-phone-incoming'
  | 'feather-phone'
  | 'feather-phone-outgoing'
  | 'feather-pie-chart'
  | 'feather-play-circle'
  | 'feather-play'
  | 'feather-plus-square'
  | 'feather-plus-circle'
  | 'feather-plus'
  | 'feather-pocket'
  | 'feather-printer'
  | 'feather-power'
  | 'feather-radio'
  | 'feather-repeat'
  | 'feather-refresh-ccw'
  | 'feather-rewind'
  | 'feather-rotate-ccw'
  | 'feather-refresh-cw'
  | 'feather-rotate-cw'
  | 'feather-save'
  | 'feather-search'
  | 'feather-server'
  | 'feather-scissors'
  | 'feather-share-'
  | 'feather-share'
  | 'feather-shield'
  | 'feather-settings'
  | 'feather-skip-back'
  | 'feather-shuffle'
  | 'feather-sidebar'
  | 'feather-skip-forward'
  | 'feather-slack'
  | 'feather-slash'
  | 'feather-smartphone'
  | 'feather-square'
  | 'feather-speaker'
  | 'feather-star'
  | 'feather-stop-circle'
  | 'feather-sun'
  | 'feather-sunrise'
  | 'feather-tablet'
  | 'feather-tag'
  | 'feather-sunset'
  | 'feather-target'
  | 'feather-thermometer'
  | 'feather-thumbs-up'
  | 'feather-thumbs-down'
  | 'feather-toggle-left'
  | 'feather-toggle-right'
  | 'feather-trash-'
  | 'feather-trash'
  | 'feather-trending-up'
  | 'feather-trending-down'
  | 'feather-triangle'
  | 'feather-type'
  | 'feather-twitter'
  | 'feather-upload'
  | 'feather-umbrella'
  | 'feather-upload-cloud'
  | 'feather-unlock'
  | 'feather-user-check'
  | 'feather-user-minus'
  | 'feather-user-plus'
  | 'feather-user-x'
  | 'feather-user'
  | 'feather-users'
  | 'feather-video-off'
  | 'feather-video'
  | 'feather-voicemail'
  | 'feather-volume-x'
  | 'feather-volume-'
  | 'feather-volume-1'
  | 'feather-volume'
  | 'feather-watch'
  | 'feather-wifi'
  | 'feather-x-square'
  | 'feather-wind'
  | 'feather-x-circle'
  | 'feather-zap'
  | 'feather-zoom-in'
  | 'feather-zoom-out'
  | 'feather-command'
  | 'feather-cloud'
  | 'feather-hash'
  | 'feather-headphones'
  | 'feather-underline'
  | 'feather-italic'
  | 'feather-bold'
  | 'feather-crop'
  | 'feather-help-circle'
  | 'feather-paperclip'
  | 'feather-shopping-cart'
  | 'feather-tv'
  | 'feather-wifi-off'
  | 'feather-minimize'
  | 'feather-maximize'
  | 'feather-gitlab'
  | 'feather-sliders'
  | 'feather-star-on'
  | 'feather-heart-on'
  | 'feather-archive'
  | 'feather-arrow-down-circle'
  | 'feather-arrow-up-circle'
  | 'feather-arrow-left-circle'
  | 'feather-arrow-right-circle'
  | 'feather-bar-chart-line-2'
  | 'feather-bar-chart-line'
  | 'feather-book-open'
  | 'feather-code'
  | 'feather-database'
  | 'feather-dollar-sign'
  | 'feather-folder-plus'
  | 'feather-gift'
  | 'feather-folder-minus'
  | 'feather-git-commit'
  | 'feather-git-branch'
  | 'feather-git-pull-request'
  | 'feather-git-merge'
  | 'feather-linkedin'
  | 'feather-hard-drive'
  | 'feather-more-vertical-'
  | 'feather-more-horizontal-'
  | 'feather-rss'
  | 'feather-send'
  | 'feather-shield-off'
  | 'feather-shopping-bag'
  | 'feather-terminal'
  | 'feather-truck'
  | 'feather-zap-off'
  | 'feather-youtube'
  | 'unicons-abacus'
  | 'unicons-accessible-icon-alt'
  | 'unicons-adjust'
  | 'unicons-adjust-alt'
  | 'unicons-adjust-circle'
  | 'unicons-adjust-half'
  | 'unicons-adobe'
  | 'unicons-adobe-alt'
  | 'unicons-0-plus'
  | 'unicons-3-plus'
  | 'unicons-6-plus'
  | 'unicons-10-plus'
  | 'unicons-12-plus'
  | 'unicons-13-plus'
  | 'unicons-16-plus'
  | 'unicons-17-plus'
  | 'unicons-18-plus'
  | 'unicons-21-plus'
  | 'unicons-500px'
  | 'unicons-align-left-justify'
  | 'unicons-align-letter-right'
  | 'unicons-align-right'
  | 'unicons-align-right-justify'
  | 'unicons-airplay'
  | 'unicons-align'
  | 'unicons-align-alt'
  | 'unicons-align-center'
  | 'unicons-align-center-alt'
  | 'unicons-align-center-h'
  | 'unicons-align-center-justify'
  | 'unicons-align-center-v'
  | 'unicons-align-justify'
  | 'unicons-align-left'
  | 'unicons-archive-alt'
  | 'unicons-archway'
  | 'unicons-arrow'
  | 'unicons-arrow-break'
  | 'unicons-amazon'
  | 'unicons-ambulance'
  | 'unicons-analysis'
  | 'unicons-analytics'
  | 'unicons-anchor'
  | 'unicons-android'
  | 'unicons-android-alt'
  | 'unicons-android-phone-slash'
  | 'unicons-angle-double-down'
  | 'unicons-angle-double-left'
  | 'unicons-angle-double-right'
  | 'unicons-angle-double-up'
  | 'unicons-angle-down'
  | 'unicons-angle-left'
  | 'unicons-angle-left-b'
  | 'unicons-angle-right'
  | 'unicons-angle-right-b'
  | 'unicons-angle-up'
  | 'unicons-angry'
  | 'unicons-ankh'
  | 'unicons-annoyed'
  | 'unicons-annoyed-alt'
  | 'unicons-apple'
  | 'unicons-apple-alt'
  | 'unicons-apps'
  | 'unicons-archive'
  | 'unicons-arrow-circle-left'
  | 'unicons-arrow-circle-right'
  | 'unicons-arrow-circle-up'
  | 'unicons-arrow-circle-down'
  | 'unicons-arrows-h'
  | 'unicons-arrows-h-alt'
  | 'unicons-arrows-left-down'
  | 'unicons-arrows-maximize'
  | 'unicons-arrows-merge'
  | 'unicons-arrows-resize'
  | 'unicons-arrows-resize-h'
  | 'unicons-arrows-resize-v'
  | 'unicons-arrows-right-down'
  | 'unicons-arrow-compress-h'
  | 'unicons-arrow-down'
  | 'unicons-arrow-down-left'
  | 'unicons-arrow-down-right'
  | 'unicons-arrow-from-right'
  | 'unicons-arrow-from-top'
  | 'unicons-arrow-growth'
  | 'unicons-arrow-left'
  | 'unicons-arrow-random'
  | 'unicons-arrow-resize-diagonal'
  | 'unicons-arrow-right'
  | 'unicons-baby-carriage'
  | 'unicons-backpack'
  | 'unicons-backspace'
  | 'unicons-arrows-shrink-h'
  | 'unicons-arrows-shrink-v'
  | 'unicons-arrows-up-right'
  | 'unicons-arrows-v'
  | 'unicons-arrows-v-alt'
  | 'unicons-arrow-to-bottom'
  | 'unicons-arrow-to-right'
  | 'unicons-arrow-up'
  | 'unicons-arrow-up-left'
  | 'unicons-arrow-up-right'
  | 'unicons-assistive-listening-systems'
  | 'unicons-asterisk'
  | 'unicons-at'
  | 'unicons-atom'
  | 'unicons-auto-flash'
  | 'unicons-award'
  | 'unicons-award-alt'
  | 'unicons-bed-double'
  | 'unicons-behance'
  | 'unicons-behance-alt'
  | 'unicons-bell'
  | 'unicons-bell-school'
  | 'unicons-backward'
  | 'unicons-bag'
  | 'unicons-bag-alt'
  | 'unicons-bag-slash'
  | 'unicons-balance-scale'
  | 'unicons-ban'
  | 'unicons-band-aid'
  | 'unicons-bars'
  | 'unicons-baseball-ball'
  | 'unicons-basketball'
  | 'unicons-basketball-hoop'
  | 'unicons-bath'
  | 'unicons-battery-bolt'
  | 'unicons-battery-empty'
  | 'unicons-bed'
  | 'unicons-car-sideview'
  | 'unicons-car-slash'
  | 'unicons-car-wash'
  | 'unicons-cell'
  | 'unicons-celsius'
  | 'unicons-channel'
  | 'unicons-channel-add'
  | 'unicons-bell-slash'
  | 'unicons-bill'
  | 'unicons-bing'
  | 'unicons-bitcoin'
  | 'unicons-bitcoin-alt'
  | 'unicons-bitcoin-circle'
  | 'unicons-bitcoin-sign'
  | 'unicons-black-berry'
  | 'unicons-blogger'
  | 'unicons-blogger-alt'
  | 'unicons-bluetooth-b'
  | 'unicons-bold'
  | 'unicons-bolt'
  | 'unicons-bolt-alt'
  | 'unicons-bolt-slash'
  | 'unicons-book'
  | 'unicons-book-alt'
  | 'unicons-bookmark'
  | 'unicons-bookmark-full'
  | 'unicons-book-medical'
  | 'unicons-book-open'
  | 'unicons-book-reader'
  | 'unicons-books'
  | 'unicons-boombox'
  | 'unicons-border-alt'
  | 'unicons-border-bottom'
  | 'unicons-border-clear'
  | 'unicons-border-horizontal'
  | 'unicons-border-inner'
  | 'unicons-border-left'
  | 'unicons-border-out'
  | 'unicons-border-right'
  | 'unicons-border-top'
  | 'unicons-border-vertical'
  | 'unicons-bowling-ball'
  | 'unicons-box'
  | 'unicons-brackets-curly'
  | 'unicons-brain'
  | 'unicons-briefcase'
  | 'unicons-briefcase-alt'
  | 'unicons-bright'
  | 'unicons-brightness'
  | 'unicons-brightness-empty'
  | 'unicons-brightness-half'
  | 'unicons-brightness-low'
  | 'unicons-brightness-minus'
  | 'unicons-brightness-plus'
  | 'unicons-bring-bottom'
  | 'unicons-bring-front'
  | 'unicons-browser'
  | 'unicons-brush-alt'
  | 'unicons-bug'
  | 'unicons-building'
  | 'unicons-bullseye'
  | 'unicons-bus'
  | 'unicons-bus-alt'
  | 'unicons-bus-school'
  | 'unicons-calculator'
  | 'unicons-calculator-alt'
  | 'unicons-calendar-alt'
  | 'unicons-calendar-slash'
  | 'unicons-calender'
  | 'unicons-calling'
  | 'unicons-camera'
  | 'unicons-camera-change'
  | 'unicons-camera-plus'
  | 'unicons-camera-slash'
  | 'unicons-cancel'
  | 'unicons-capsule'
  | 'unicons-capture'
  | 'unicons-car'
  | 'unicons-card-atm'
  | 'unicons-caret-right'
  | 'unicons-club'
  | 'unicons-code-branch'
  | 'unicons-coffee'
  | 'unicons-cog'
  | 'unicons-chart'
  | 'unicons-chart-bar'
  | 'unicons-chart-bar-alt'
  | 'unicons-chart-down'
  | 'unicons-chart-growth'
  | 'unicons-chart-growth-alt'
  | 'unicons-chart-line'
  | 'unicons-chart-pie'
  | 'unicons-chart-pie-alt'
  | 'unicons-chat'
  | 'unicons-chat-bubble-user'
  | 'unicons-chat-info'
  | 'unicons-check'
  | 'unicons-check-circle'
  | 'unicons-check-square'
  | 'unicons-circle'
  | 'unicons-circle-layer'
  | 'unicons-circuit'
  | 'unicons-clapper-board'
  | 'unicons-clinic-medical'
  | 'unicons-clipboard'
  | 'unicons-clipboard-alt'
  | 'unicons-clipboard-blank'
  | 'unicons-clipboard-notes'
  | 'unicons-clock'
  | 'unicons-clock-eight'
  | 'unicons-clock-five'
  | 'unicons-clock-nine'
  | 'unicons-clock-seven'
  | 'unicons-clock-ten'
  | 'unicons-clock-three'
  | 'unicons-clock-two'
  | 'unicons-closed-captioning'
  | 'unicons-closed-captioning-slash'
  | 'unicons-cloud'
  | 'unicons-cloud-block'
  | 'unicons-cloud-bookmark'
  | 'unicons-cloud-check'
  | 'unicons-cloud-computing'
  | 'unicons-cloud-database-tree'
  | 'unicons-cloud-data-connection'
  | 'unicons-cloud-download'
  | 'unicons-cloud-drizzle'
  | 'unicons-cloud-exclamation'
  | 'unicons-cloud-hail'
  | 'unicons-cloud-heart'
  | 'unicons-cloud-info'
  | 'unicons-cloud-lock'
  | 'unicons-cloud-meatball'
  | 'unicons-cloud-moon'
  | 'unicons-cloud-moon-hail'
  | 'unicons-cloud-moon-meatball'
  | 'unicons-cloud-moon-rain'
  | 'unicons-cloud-moon-showers'
  | 'unicons-cloud-question'
  | 'unicons-cloud-rain'
  | 'unicons-cloud-rain-sun'
  | 'unicons-cloud-redo'
  | 'unicons-clouds'
  | 'unicons-cloud-share'
  | 'unicons-cloud-shield'
  | 'unicons-cloud-showers'
  | 'unicons-cloud-showers-alt'
  | 'unicons-cloud-showers-heavy'
  | 'unicons-cloud-slash'
  | 'unicons-cloud-sun'
  | 'unicons-cloud-sun-hail'
  | 'unicons-cloud-sun-meatball'
  | 'unicons-cloud-sun-rain'
  | 'unicons-cloud-sun-rain-alt'
  | 'unicons-cloud-sun-tear'
  | 'unicons-cloud-times'
  | 'unicons-cloud-unlock'
  | 'unicons-cloud-upload'
  | 'unicons-cloud-wifi'
  | 'unicons-cloud-wind'
  | 'unicons-copy-landscape'
  | 'unicons-copyright'
  | 'unicons-corner-down-left'
  | 'unicons-corner-down-right'
  | 'unicons-corner-down-right-alt'
  | 'unicons-corner-left-down'
  | 'unicons-corner-right-down'
  | 'unicons-corner-up-left'
  | 'unicons-corner-up-left-alt'
  | 'unicons-corner-up-right'
  | 'unicons-coins'
  | 'unicons-columns'
  | 'unicons-comment'
  | 'unicons-comment-add'
  | 'unicons-comment-alt'
  | 'unicons-comment-alt-block'
  | 'unicons-comment-alt-chart-lines'
  | 'unicons-comment-alt-check'
  | 'unicons-comment-alt-dots'
  | 'unicons-comment-alt-download'
  | 'unicons-comment-alt-edit'
  | 'unicons-comment-alt-exclamation'
  | 'unicons-comment-alt-heart'
  | 'unicons-comment-alt-image'
  | 'unicons-comment-alt-info'
  | 'unicons-comment-alt-lines'
  | 'unicons-comment-alt-lock'
  | 'unicons-comment-alt-medical'
  | 'unicons-comment-alt-message'
  | 'unicons-comment-alt-notes'
  | 'unicons-comment-alt-plus'
  | 'unicons-comment-alt-question'
  | 'unicons-comment-alt-redo'
  | 'unicons-comment-alt-search'
  | 'unicons-comment-alt-share'
  | 'unicons-comment-alt-shield'
  | 'unicons-comment-alt-slash'
  | 'unicons-comment-alt-upload'
  | 'unicons-comment-alt-verify'
  | 'unicons-comment-block'
  | 'unicons-comment-chart-line'
  | 'unicons-comment-check'
  | 'unicons-comment-dots'
  | 'unicons-comment-download'
  | 'unicons-comment-edit'
  | 'unicons-comment-exclamation'
  | 'unicons-comment-heart'
  | 'unicons-comment-image'
  | 'unicons-comment-info'
  | 'unicons-comment-info-alt'
  | 'unicons-comment-lines'
  | 'unicons-comment-lock'
  | 'unicons-comment-medical'
  | 'unicons-comment-message'
  | 'unicons-comment-notes'
  | 'unicons-comment-plus'
  | 'unicons-comment-question'
  | 'unicons-comment-redo'
  | 'unicons-comments'
  | 'unicons-comments-alt'
  | 'unicons-comment-search'
  | 'unicons-comment-share'
  | 'unicons-comment-shield'
  | 'unicons-comment-slash'
  | 'unicons-comment-upload'
  | 'unicons-comment-verify'
  | 'unicons-compact-disc'
  | 'unicons-comparison'
  | 'unicons-compass'
  | 'unicons-compress'
  | 'unicons-compress-alt'
  | 'unicons-compress-alt-left'
  | 'unicons-compress-arrows'
  | 'unicons-compress-lines'
  | 'unicons-compress-point'
  | 'unicons-compress-v'
  | 'unicons-confused'
  | 'unicons-constructor'
  | 'unicons-copy'
  | 'unicons-copy-alt'
  | 'unicons-envelope-info'
  | 'unicons-envelope-lock'
  | 'unicons-envelope-minus'
  | 'unicons-envelope-open'
  | 'unicons-envelope-question'
  | 'unicons-corner-up-right-alt'
  | 'unicons-coronavirus'
  | 'unicons-create-dashboard'
  | 'unicons-creative-commons-pd'
  | 'unicons-credit-card'
  | 'unicons-credit-card-search'
  | 'unicons-crockery'
  | 'unicons-crop-alt'
  | 'unicons-crop-alt-rotate-left'
  | 'unicons-crop-alt-rotate-right'
  | 'unicons-crosshair'
  | 'unicons-crosshair-alt'
  | 'unicons-crosshairs'
  | 'unicons-css3-simple'
  | 'unicons-cube'
  | 'unicons-dashboard'
  | 'unicons-database'
  | 'unicons-database-alt'
  | 'unicons-data-sharing'
  | 'unicons-desert'
  | 'unicons-desktop'
  | 'unicons-desktop-alt'
  | 'unicons-desktop-alt-slash'
  | 'unicons-desktop-cloud-alt'
  | 'unicons-desktop-slash'
  | 'unicons-dialpad'
  | 'unicons-dialpad-alt'
  | 'unicons-diamond'
  | 'unicons-diary'
  | 'unicons-diary-alt'
  | 'unicons-dice-five'
  | 'unicons-dice-four'
  | 'unicons-dice-one'
  | 'unicons-dice-six'
  | 'unicons-dice-three'
  | 'unicons-dice-two'
  | 'unicons-direction'
  | 'unicons-directions'
  | 'unicons-discord'
  | 'unicons-dizzy-meh'
  | 'unicons-dna'
  | 'unicons-docker'
  | 'unicons-document-info'
  | 'unicons-document-layout-center'
  | 'unicons-document-layout-left'
  | 'unicons-document-layout-right'
  | 'unicons-dollar-alt'
  | 'unicons-dollar-sign'
  | 'unicons-dollar-sign-alt'
  | 'unicons-download-alt'
  | 'unicons-draggabledots'
  | 'unicons-dribbble'
  | 'unicons-drill'
  | 'unicons-dropbox'
  | 'unicons-dumbbell'
  | 'unicons-ear'
  | 'unicons-edit'
  | 'unicons-edit-alt'
  | 'unicons-elipsis-double-v-alt'
  | 'unicons-ellipsis-h'
  | 'unicons-ellipsis-v'
  | 'unicons-emoji'
  | 'unicons-english-to-chinese'
  | 'unicons-enter'
  | 'unicons-envelope'
  | 'unicons-envelope-add'
  | 'unicons-envelope-alt'
  | 'unicons-envelope-block'
  | 'unicons-envelope-bookmark'
  | 'unicons-envelope-check'
  | 'unicons-envelope-download'
  | 'unicons-envelope-download-alt'
  | 'unicons-envelope-edit'
  | 'unicons-envelope-exclamation'
  | 'unicons-envelope-heart'
  | 'unicons-flip-v'
  | 'unicons-flip-v-alt'
  | 'unicons-flower'
  | 'unicons-envelope-receive'
  | 'unicons-envelope-redo'
  | 'unicons-envelopes'
  | 'unicons-envelope-search'
  | 'unicons-envelope-send'
  | 'unicons-envelope-share'
  | 'unicons-envelope-shield'
  | 'unicons-envelope-star'
  | 'unicons-envelope-times'
  | 'unicons-envelope-upload'
  | 'unicons-envelope-upload-alt'
  | 'unicons-equal-circle'
  | 'unicons-estate'
  | 'unicons-euro'
  | 'unicons-euro-circle'
  | 'unicons-exchange'
  | 'unicons-exchange-alt'
  | 'unicons-exclamation'
  | 'unicons-exclamation-circle'
  | 'unicons-exclamation-octagon'
  | 'unicons-exclamation-triangle'
  | 'unicons-exclude'
  | 'unicons-exit'
  | 'unicons-expand-alt'
  | 'unicons-expand-arrows'
  | 'unicons-expand-arrows-alt'
  | 'unicons-expand-from-corner'
  | 'unicons-expand-left'
  | 'unicons-expand-right'
  | 'unicons-export'
  | 'unicons-exposure-alt'
  | 'unicons-exposure-increase'
  | 'unicons-external-link-alt'
  | 'unicons-eye'
  | 'unicons-eye-slash'
  | 'unicons-facebook'
  | 'unicons-facebook-f'
  | 'unicons-facebook-messenger'
  | 'unicons-facebook-messenger-alt'
  | 'unicons-fahrenheit'
  | 'unicons-fast-mail'
  | 'unicons-fast-mail-alt'
  | 'unicons-favorite'
  | 'unicons-feedback'
  | 'unicons-fidget-spinner'
  | 'unicons-file'
  | 'unicons-file-alt'
  | 'unicons-file-blank'
  | 'unicons-file-block-alt'
  | 'unicons-file-bookmark-alt'
  | 'unicons-file-check'
  | 'unicons-file-check-alt'
  | 'unicons-file-contract'
  | 'unicons-file-contract-dollar'
  | 'unicons-file-copy-alt'
  | 'unicons-file-download'
  | 'unicons-file-download-alt'
  | 'unicons-file-edit-alt'
  | 'unicons-file-exclamation'
  | 'unicons-file-exclamation-alt'
  | 'unicons-file-export'
  | 'unicons-file-graph'
  | 'unicons-file-heart'
  | 'unicons-file-import'
  | 'unicons-file-info-alt'
  | 'unicons-file-landscape'
  | 'unicons-file-landscape-alt'
  | 'unicons-file-lanscape-slash'
  | 'unicons-file-lock-alt'
  | 'unicons-file-medical'
  | 'unicons-file-medical-alt'
  | 'unicons-file-minus'
  | 'unicons-file-minus-alt'
  | 'unicons-file-network'
  | 'unicons-file-plus'
  | 'unicons-file-plus-alt'
  | 'unicons-file-question'
  | 'unicons-file-question-alt'
  | 'unicons-file-redo-alt'
  | 'unicons-file-search-alt'
  | 'unicons-file-share-alt'
  | 'unicons-file-shield-alt'
  | 'unicons-files-landscapes'
  | 'unicons-files-landscapes-alt'
  | 'unicons-file-slash'
  | 'unicons-file-times'
  | 'unicons-file-times-alt'
  | 'unicons-file-upload'
  | 'unicons-file-upload-alt'
  | 'unicons-film'
  | 'unicons-filter'
  | 'unicons-filter-slash'
  | 'unicons-fire'
  | 'unicons-flask'
  | 'unicons-flask-potion'
  | 'unicons-flip-h'
  | 'unicons-flip-h-alt'
  | 'unicons-image-download'
  | 'unicons-image-edit'
  | 'unicons-focus'
  | 'unicons-focus-add'
  | 'unicons-focus-target'
  | 'unicons-folder'
  | 'unicons-folder-check'
  | 'unicons-folder-download'
  | 'unicons-folder-exclamation'
  | 'unicons-folder-heart'
  | 'unicons-folder-info'
  | 'unicons-folder-lock'
  | 'unicons-folder-medical'
  | 'unicons-folder-minus'
  | 'unicons-folder-network'
  | 'unicons-folder-open'
  | 'unicons-folder-plus'
  | 'unicons-folder-question'
  | 'unicons-folder-slash'
  | 'unicons-folder-times'
  | 'unicons-folder-upload'
  | 'unicons-font'
  | 'unicons-football'
  | 'unicons-football-american'
  | 'unicons-football-ball'
  | 'unicons-forecastcloud-moon-tear'
  | 'unicons-forwaded-call'
  | 'unicons-forward'
  | 'unicons-frown'
  | 'unicons-game-structure'
  | 'unicons-gift'
  | 'unicons-github'
  | 'unicons-github-alt'
  | 'unicons-gitlab'
  | 'unicons-glass'
  | 'unicons-glass-martini'
  | 'unicons-glass-martini-alt'
  | 'unicons-glass-martini-alt-slash'
  | 'unicons-glass-tea'
  | 'unicons-globe'
  | 'unicons-gold'
  | 'unicons-golf-ball'
  | 'unicons-google'
  | 'unicons-google-drive'
  | 'unicons-google-drive-alt'
  | 'unicons-google-hangouts'
  | 'unicons-google-hangouts-alt'
  | 'unicons-google-play'
  | 'unicons-graduation-cap'
  | 'unicons-graph-bar'
  | 'unicons-grid'
  | 'unicons-grids'
  | 'unicons-grin'
  | 'unicons-grin-tongue-wink'
  | 'unicons-grin-tongue-wink-alt'
  | 'unicons-grip-horizontal-line'
  | 'unicons-hard-hat'
  | 'unicons-hdd'
  | 'unicons-headphones'
  | 'unicons-headphones-alt'
  | 'unicons-headphone-slash'
  | 'unicons-head-side'
  | 'unicons-head-side-cough'
  | 'unicons-head-side-mask'
  | 'unicons-heart'
  | 'unicons-heart-alt'
  | 'unicons-heartbeat'
  | 'unicons-heart-break'
  | 'unicons-heart-medical'
  | 'unicons-heart-rate'
  | 'unicons-heart-sign'
  | 'unicons-hindi-to-chinese'
  | 'unicons-hipchat'
  | 'unicons-history'
  | 'unicons-history-alt'
  | 'unicons-home'
  | 'unicons-home-alt'
  | 'unicons-horizontal-align-center'
  | 'unicons-horizontal-align-left'
  | 'unicons-horizontal-align-right'
  | 'unicons-horizontal-distribution-center'
  | 'unicons-horizontal-distribution-left'
  | 'unicons-horizontal-distribution-right'
  | 'unicons-hospital'
  | 'unicons-hospital-square-sign'
  | 'unicons-hospital-symbol'
  | 'unicons-hourglass'
  | 'unicons-house-user'
  | 'unicons-html3'
  | 'unicons-html3-alt'
  | 'unicons-html5'
  | 'unicons-html5-alt'
  | 'unicons-hunting'
  | 'unicons-icons'
  | 'unicons-illustration'
  | 'unicons-image'
  | 'unicons-image-alt-slash'
  | 'unicons-image-block'
  | 'unicons-image-broken'
  | 'unicons-image-check'
  | 'unicons-map-marker-edit'
  | 'unicons-map-marker-info'
  | 'unicons-map-marker-minus'
  | 'unicons-image-lock'
  | 'unicons-image-minus'
  | 'unicons-image-plus'
  | 'unicons-image-question'
  | 'unicons-image-redo'
  | 'unicons-image-resize-landscape'
  | 'unicons-image-resize-square'
  | 'unicons-images'
  | 'unicons-image-search'
  | 'unicons-image-share'
  | 'unicons-image-shield'
  | 'unicons-image-slash'
  | 'unicons-image-times'
  | 'unicons-image-upload'
  | 'unicons-image-v'
  | 'unicons-import'
  | 'unicons-inbox'
  | 'unicons-incoming-call'
  | 'unicons-info'
  | 'unicons-info-circle'
  | 'unicons-instagram'
  | 'unicons-instagram-alt'
  | 'unicons-intercom'
  | 'unicons-intercom-alt'
  | 'unicons-invoice'
  | 'unicons-italic'
  | 'unicons-jackhammer'
  | 'unicons-java-script'
  | 'unicons-kayak'
  | 'unicons-keyboard'
  | 'unicons-keyboard-alt'
  | 'unicons-keyboard-hide'
  | 'unicons-keyboard-show'
  | 'unicons-keyhole-circle'
  | 'unicons-keyhole-square'
  | 'unicons-keyhole-square-full'
  | 'unicons-key-skeleton'
  | 'unicons-key-skeleton-alt'
  | 'unicons-kid'
  | 'unicons-label'
  | 'unicons-label-alt'
  | 'unicons-lamp'
  | 'unicons-language'
  | 'unicons-laptop'
  | 'unicons-laptop-cloud'
  | 'unicons-laptop-connection'
  | 'unicons-laughing'
  | 'unicons-layer-group'
  | 'unicons-layer-group-slash'
  | 'unicons-layers'
  | 'unicons-layers-alt'
  | 'unicons-layers-slash'
  | 'unicons-left-arrow-from-left'
  | 'unicons-left-arrow-to-left'
  | 'unicons-left-indent'
  | 'unicons-left-indent-alt'
  | 'unicons-left-to-right-text-direction'
  | 'unicons-letter-chinese-a'
  | 'unicons-letter-english-a'
  | 'unicons-letter-hindi-a'
  | 'unicons-letter-japanese-a'
  | 'unicons-life-ring'
  | 'unicons-lightbulb'
  | 'unicons-lightbulb-alt'
  | 'unicons-line'
  | 'unicons-line-alt'
  | 'unicons-line-spacing'
  | 'unicons-link'
  | 'unicons-link-add'
  | 'unicons-link-alt'
  | 'unicons-link-broken'
  | 'unicons-linkedin'
  | 'unicons-linkedin-alt'
  | 'unicons-link-h'
  | 'unicons-linux'
  | 'unicons-lira-sign'
  | 'unicons-list-ol'
  | 'unicons-list-ol-alt'
  | 'unicons-list-ui-alt'
  | 'unicons-list-ul'
  | 'unicons-location-arrow'
  | 'unicons-location-arrow-alt'
  | 'unicons-location-pin-alt'
  | 'unicons-location-point'
  | 'unicons-lock'
  | 'unicons-lock-access'
  | 'unicons-lock-alt'
  | 'unicons-lock-open-alt'
  | 'unicons-lock-slash'
  | 'unicons-lottiefiles'
  | 'unicons-lottiefiles-alt'
  | 'unicons-luggage-cart'
  | 'unicons-mailbox'
  | 'unicons-mailbox-alt'
  | 'unicons-map'
  | 'unicons-map-marker'
  | 'unicons-map-marker-alt'
  | 'unicons-phone'
  | 'unicons-phone-alt'
  | 'unicons-phone-pause'
  | 'unicons-map-marker-plus'
  | 'unicons-map-marker-question'
  | 'unicons-map-marker-shield'
  | 'unicons-map-marker-slash'
  | 'unicons-map-pin'
  | 'unicons-map-pin-alt'
  | 'unicons-mars'
  | 'unicons-master-card'
  | 'unicons-maximize-left'
  | 'unicons-medal'
  | 'unicons-medical-drip'
  | 'unicons-medical-square'
  | 'unicons-medical-square-full'
  | 'unicons-medium-m'
  | 'unicons-medkit'
  | 'unicons-meeting-board'
  | 'unicons-megaphone'
  | 'unicons-meh'
  | 'unicons-meh-alt'
  | 'unicons-meh-closed-eye'
  | 'unicons-message'
  | 'unicons-metro'
  | 'unicons-microphone'
  | 'unicons-microphone-slash'
  | 'unicons-microscope'
  | 'unicons-microsoft'
  | 'unicons-minus'
  | 'unicons-minus-circle'
  | 'unicons-minus-path'
  | 'unicons-minus-square'
  | 'unicons-minus-square-full'
  | 'unicons-missed-call'
  | 'unicons-mobile-android'
  | 'unicons-mobile-android-alt'
  | 'unicons-mobile-vibrate'
  | 'unicons-modem'
  | 'unicons-moneybag'
  | 'unicons-moneybag-alt'
  | 'unicons-money-bill'
  | 'unicons-money-bill-slash'
  | 'unicons-money-bill-stack'
  | 'unicons-money-insert'
  | 'unicons-money-stack'
  | 'unicons-money-withdraw'
  | 'unicons-money-withdrawal'
  | 'unicons-monitor'
  | 'unicons-monitor-heart-rate'
  | 'unicons-moon'
  | 'unicons-moon-eclipse'
  | 'unicons-moonset'
  | 'unicons-mountains'
  | 'unicons-mountains-sun'
  | 'unicons-mouse'
  | 'unicons-mouse-alt'
  | 'unicons-mouse-alt-2'
  | 'unicons-multiply'
  | 'unicons-music'
  | 'unicons-music-note'
  | 'unicons-music-tune-slash'
  | 'unicons-n-a'
  | 'unicons-navigator'
  | 'unicons-nerd'
  | 'unicons-newspaper'
  | 'unicons-ninja'
  | 'unicons-no-entry'
  | 'unicons-notebooks'
  | 'unicons-notes'
  | 'unicons-object-group'
  | 'unicons-object-ungroup'
  | 'unicons-octagon'
  | 'unicons-okta'
  | 'unicons-opera'
  | 'unicons-opera-alt'
  | 'unicons-outgoing-call'
  | 'unicons-package'
  | 'unicons-padlock'
  | 'unicons-pagelines'
  | 'unicons-pagerduty'
  | 'unicons-paint-tool'
  | 'unicons-palette'
  | 'unicons-panel-add'
  | 'unicons-panorama-h'
  | 'unicons-panorama-h-alt'
  | 'unicons-panorama-v'
  | 'unicons-paperclip'
  | 'unicons-paragraph'
  | 'unicons-parcel'
  | 'unicons-parking-circle'
  | 'unicons-parking-square'
  | 'unicons-pathfinder'
  | 'unicons-pathfinder-unite'
  | 'unicons-pause'
  | 'unicons-pause-circle'
  | 'unicons-paypal'
  | 'unicons-pen'
  | 'unicons-pentagon'
  | 'unicons-percentage'
  | 'unicons-setting'
  | 'unicons-share'
  | 'unicons-share-alt'
  | 'unicons-shield'
  | 'unicons-phone-slash'
  | 'unicons-phone-times'
  | 'unicons-phone-volume'
  | 'unicons-picture'
  | 'unicons-pizza-slice'
  | 'unicons-plane'
  | 'unicons-plane-arrival'
  | 'unicons-plane-departure'
  | 'unicons-plane-fly'
  | 'unicons-play'
  | 'unicons-play-circle'
  | 'unicons-plug'
  | 'unicons-plus'
  | 'unicons-plus-circle'
  | 'unicons-plus-square'
  | 'unicons-podium'
  | 'unicons-polygon'
  | 'unicons-postcard'
  | 'unicons-post-stamp'
  | 'unicons-pound'
  | 'unicons-pound-circle'
  | 'unicons-power'
  | 'unicons-prescription-bottle'
  | 'unicons-presentation'
  | 'unicons-presentation-check'
  | 'unicons-presentation-edit'
  | 'unicons-presentation-line'
  | 'unicons-presentation-lines-alt'
  | 'unicons-presentation-minus'
  | 'unicons-presentation-play'
  | 'unicons-presentation-plus'
  | 'unicons-presentation-times'
  | 'unicons-previous'
  | 'unicons-pricetag-alt'
  | 'unicons-print'
  | 'unicons-print-slash'
  | 'unicons-process'
  | 'unicons-processor'
  | 'unicons-programming-language'
  | 'unicons-pump'
  | 'unicons-puzzle-piece'
  | 'unicons-qrcode-scan'
  | 'unicons-question'
  | 'unicons-question-circle'
  | 'unicons-rainbow'
  | 'unicons-raindrops'
  | 'unicons-raindrops-alt'
  | 'unicons-react'
  | 'unicons-receipt'
  | 'unicons-receipt-alt'
  | 'unicons-record-audio'
  | 'unicons-reddit-alien-alt'
  | 'unicons-redo'
  | 'unicons-refresh'
  | 'unicons-registered'
  | 'unicons-repeat'
  | 'unicons-restaurant'
  | 'unicons-right-indent-alt'
  | 'unicons-right-to-left-text-direction'
  | 'unicons-robot'
  | 'unicons-rocket'
  | 'unicons-rope-way'
  | 'unicons-rotate-360'
  | 'unicons-rss'
  | 'unicons-rss-alt'
  | 'unicons-rss-interface'
  | 'unicons-ruler'
  | 'unicons-ruler-combined'
  | 'unicons-rupee-sign'
  | 'unicons-sad'
  | 'unicons-sad-cry'
  | 'unicons-sad-crying'
  | 'unicons-sad-dizzy'
  | 'unicons-sad-squint'
  | 'unicons-sanitizer'
  | 'unicons-sanitizer-alt'
  | 'unicons-save'
  | 'unicons-scaling-left'
  | 'unicons-scaling-right'
  | 'unicons-scenery'
  | 'unicons-schedule'
  | 'unicons-screw'
  | 'unicons-scroll'
  | 'unicons-scroll-h'
  | 'unicons-search'
  | 'unicons-search-alt'
  | 'unicons-search-minus'
  | 'unicons-search-plus'
  | 'unicons-selfie'
  | 'unicons-server'
  | 'unicons-server-alt'
  | 'unicons-server-connection'
  | 'unicons-server-network'
  | 'unicons-server-network-alt'
  | 'unicons-servers'
  | 'unicons-servicemark'
  | 'unicons-sync'
  | 'unicons-sync-exclamation'
  | 'unicons-shield-check'
  | 'unicons-shield-exclamation'
  | 'unicons-shield-plus'
  | 'unicons-shield-question'
  | 'unicons-shield-slash'
  | 'unicons-ship'
  | 'unicons-shop'
  | 'unicons-shopping-bag'
  | 'unicons-shopping-basket'
  | 'unicons-shopping-cart'
  | 'unicons-shopping-cart-alt'
  | 'unicons-shovel'
  | 'unicons-shrink'
  | 'unicons-shuffle'
  | 'unicons-shutter'
  | 'unicons-shutter-alt'
  | 'unicons-sick'
  | 'unicons-sigma'
  | 'unicons-signal'
  | 'unicons-signal-alt'
  | 'unicons-signal-alt-3'
  | 'unicons-sign-alt'
  | 'unicons-signin'
  | 'unicons-sign-in-alt'
  | 'unicons-sign-left'
  | 'unicons-signout'
  | 'unicons-sign-out-alt'
  | 'unicons-sign-right'
  | 'unicons-silence'
  | 'unicons-silent-squint'
  | 'unicons-sim-card'
  | 'unicons-sitemap'
  | 'unicons-skip-forward'
  | 'unicons-skip-forward-alt'
  | 'unicons-skip-forward-circle'
  | 'unicons-skype'
  | 'unicons-skype-alt'
  | 'unicons-slack'
  | 'unicons-slack-alt'
  | 'unicons-slider-h'
  | 'unicons-slider-h-range'
  | 'unicons-sliders-v'
  | 'unicons-sliders-v-alt'
  | 'unicons-smile'
  | 'unicons-smile-beam'
  | 'unicons-smile-dizzy'
  | 'unicons-smile-squint-wink'
  | 'unicons-smile-squint-wink-alt'
  | 'unicons-smile-wink'
  | 'unicons-smile-wink-alt'
  | 'unicons-snapchat-alt'
  | 'unicons-snapchat-ghost'
  | 'unicons-snapchat-square'
  | 'unicons-snowflake'
  | 'unicons-snow-flake'
  | 'unicons-snowflake-alt'
  | 'unicons-social-distancing'
  | 'unicons-sort'
  | 'unicons-sort-amount-down'
  | 'unicons-sort-amount-up'
  | 'unicons-sorting'
  | 'unicons-space-key'
  | 'unicons-spade'
  | 'unicons-sperms'
  | 'unicons-spin'
  | 'unicons-spinner'
  | 'unicons-spinner-alt'
  | 'unicons-square'
  | 'unicons-square-full'
  | 'unicons-square-shape'
  | 'unicons-squint'
  | 'unicons-star'
  | 'unicons-star-half-alt'
  | 'unicons-step-backward'
  | 'unicons-step-backward-alt'
  | 'unicons-step-backward-circle'
  | 'unicons-step-forward'
  | 'unicons-stethoscope'
  | 'unicons-stethoscope-alt'
  | 'unicons-stop-circle'
  | 'unicons-stopwatch'
  | 'unicons-stopwatch-slash'
  | 'unicons-store'
  | 'unicons-store-alt'
  | 'unicons-store-slash'
  | 'unicons-streering'
  | 'unicons-stretcher'
  | 'unicons-subject'
  | 'unicons-subway'
  | 'unicons-subway-alt'
  | 'unicons-suitcase'
  | 'unicons-suitcase-alt'
  | 'unicons-sun'
  | 'unicons-sunset'
  | 'unicons-surprise'
  | 'unicons-swatchbook'
  | 'unicons-swiggy'
  | 'unicons-swimmer'
  | 'unicons-vertical-distribute-bottom'
  | 'unicons-vertical-distribution-center'
  | 'unicons-sync-slash'
  | 'unicons-syringe'
  | 'unicons-table'
  | 'unicons-tablet'
  | 'unicons-table-tennis'
  | 'unicons-tablets'
  | 'unicons-tachometer-fast'
  | 'unicons-tachometer-fast-alt'
  | 'unicons-tag'
  | 'unicons-tag-alt'
  | 'unicons-tape'
  | 'unicons-taxi'
  | 'unicons-tear'
  | 'unicons-telegram'
  | 'unicons-telegram-alt'
  | 'unicons-telescope'
  | 'unicons-temperature'
  | 'unicons-temperature-empty'
  | 'unicons-temperature-half'
  | 'unicons-temperature-minus'
  | 'unicons-temperature-plus'
  | 'unicons-temperature-quarter'
  | 'unicons-temperature-three-quarter'
  | 'unicons-tennis-ball'
  | 'unicons-text'
  | 'unicons-text-fields'
  | 'unicons-text-size'
  | 'unicons-text-strike-through'
  | 'unicons-th'
  | 'unicons-thermometer'
  | 'unicons-th-large'
  | 'unicons-th-slash'
  | 'unicons-thumbs-down'
  | 'unicons-thumbs-up'
  | 'unicons-thunderstorm'
  | 'unicons-thunderstorm-moon'
  | 'unicons-thunderstorm-sun'
  | 'unicons-ticket'
  | 'unicons-times'
  | 'unicons-times-circle'
  | 'unicons-times-square'
  | 'unicons-toggle-off'
  | 'unicons-toggle-on'
  | 'unicons-toilet-paper'
  | 'unicons-top-arrow-from-top'
  | 'unicons-top-arrow-to-top'
  | 'unicons-tornado'
  | 'unicons-trademark'
  | 'unicons-trademark-circle'
  | 'unicons-traffic-barrier'
  | 'unicons-traffic-light'
  | 'unicons-transaction'
  | 'unicons-trash'
  | 'unicons-trash-alt'
  | 'unicons-trees'
  | 'unicons-triangle'
  | 'unicons-trophy'
  | 'unicons-trowel'
  | 'unicons-truck'
  | 'unicons-truck-loading'
  | 'unicons-tumblr'
  | 'unicons-tumblr-alt'
  | 'unicons-tumblr-square'
  | 'unicons-tv-retro'
  | 'unicons-tv-retro-slash'
  | 'unicons-twitter'
  | 'unicons-twitter-alt'
  | 'unicons-umbrella'
  | 'unicons-unamused'
  | 'unicons-underline'
  | 'unicons-university'
  | 'unicons-unlock'
  | 'unicons-unlock-alt'
  | 'unicons-upload'
  | 'unicons-upload-alt'
  | 'unicons-usd-circle'
  | 'unicons-usd-square'
  | 'unicons-user'
  | 'unicons-user-arrows'
  | 'unicons-user-check'
  | 'unicons-user-circle'
  | 'unicons-user-exclamation'
  | 'unicons-user-location'
  | 'unicons-user-md'
  | 'unicons-user-minus'
  | 'unicons-user-nurse'
  | 'unicons-user-plus'
  | 'unicons-users-alt'
  | 'unicons-user-square'
  | 'unicons-user-times'
  | 'unicons-utensils'
  | 'unicons-utensils-alt'
  | 'unicons-vector-square'
  | 'unicons-vector-square-alt'
  | 'unicons-venus'
  | 'unicons-vertical-align-bottom'
  | 'unicons-vertical-align-center'
  | 'unicons-vertical-align-top'
  | 'unicons-x'
  | 'unicons-x-add'
  | 'unicons-yen'
  | 'unicons-yen-circle'
  | 'unicons-yin-yang'
  | 'unicons-youtube'
  | 'unicons-vertical-distribution-top'
  | 'unicons-video'
  | 'unicons-video-question'
  | 'unicons-video-slash'
  | 'unicons-virus-slash'
  | 'unicons-visual-studio'
  | 'unicons-vk'
  | 'unicons-vk-alt'
  | 'unicons-voicemail'
  | 'unicons-voicemail-rectangle'
  | 'unicons-volleyball'
  | 'unicons-volume'
  | 'unicons-volume-down'
  | 'unicons-volume-mute'
  | 'unicons-volume-off'
  | 'unicons-volume-up'
  | 'unicons-vuejs'
  | 'unicons-vuejs-alt'
  | 'unicons-wall'
  | 'unicons-wallet'
  | 'unicons-watch'
  | 'unicons-watch-alt'
  | 'unicons-water'
  | 'unicons-water-drop-slash'
  | 'unicons-water-glass'
  | 'unicons-webcam'
  | 'unicons-web-grid'
  | 'unicons-web-grid-alt'
  | 'unicons-web-section'
  | 'unicons-web-section-alt'
  | 'unicons-weight'
  | 'unicons-whatsapp'
  | 'unicons-whatsapp-alt'
  | 'unicons-wheel-barrow'
  | 'unicons-wheelchair'
  | 'unicons-wheelchair-alt'
  | 'unicons-wifi'
  | 'unicons-wifi-router'
  | 'unicons-wifi-slash'
  | 'unicons-wind'
  | 'unicons-wind-moon'
  | 'unicons-window'
  | 'unicons-window-grid'
  | 'unicons-window-maximize'
  | 'unicons-windows'
  | 'unicons-window-section'
  | 'unicons-windsock'
  | 'unicons-wind-sun'
  | 'unicons-windy'
  | 'unicons-wordpress'
  | 'unicons-wordpress-simple'
  | 'unicons-wrap-text'
  | 'unicons-wrench';
export interface IconProps extends React.HTMLProps<HTMLElement> {
  name: IconName;
  fontSize?: number | string;
  sizeUnit?:
    | '%'
    | 'cm'
    | 'em'
    | 'ex'
    | 'in'
    | 'mm'
    | 'pc'
    | 'pt'
    | 'px'
    | 'rem'
    | 'vw'
    | 'vh'
    | 'vmin'
    | 'vmax';
  color?: string;
}

const Icon: React.FC<IconProps> = styled.i.attrs<IconProps>(({ name, ...rest }) => ({
  className: 'icon-' + name,
  ...rest,
}))<IconProps>`
  ${({ color }) => {
    if (color) return 'color: ' + color + ';\n';
    else return '';
  }}
  font-size: ${({ fontSize = '24px' }) => {
    if (typeof fontSize === 'string') return fontSize;
    return fontSize + 'px';
  }};
  display: inline-block;
`;

export default Icon;
