import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { v4 } from 'uuid';
import styled from 'styled-components';
import InfoIcon from '../../assets/Info.svg';
import { usePopper } from 'react-popper';
import ExitIcon from '../../assets/exit.svg';

export type TooltipTypes = {
  children?: React.ReactNode;
  id?: string;
  infoText: React.ReactNode;
  displayStatic?: boolean;
  cloudOnly?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export type BoundingType = {
  top: boolean | undefined;
  bottom: boolean | undefined;
  left: boolean | undefined;
  right: boolean | undefined;
};

const Info = styled.img`
  width: 13px;
  height: 13px;
  min-width: 13px;
  min-height: 13px;
  cursor: pointer;
  padding: 0;
  position: relative;
`;

const InfoText = styled.p`
  text-align: left;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div<{ displayStatic?: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.centerCard};
`;

const Close = styled.img`
  margin-left: 29px;
  width: 15px;
  cursor: pointer;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloudElement = styled.div`
  background: ${({ theme }) => theme.colors.tooltip.bgColor};
  border: ${({ theme }) => `2px solid ${theme.colors.tooltip.brdrColor}`};
  opacity: 1;
  border-radius: 12px;
  padding: 12px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  word-break: break-word;
  max-width: 280px;
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  ${({ theme }) =>
    theme.themeHelpers.breakpoints.only('xs') +
    ` {
     max-width: 200px;
  }`}

  @media screen and (max-width: 400px) {
    margin: 0 0 12px -10px;
  }
  @media screen and (max-width: 320px) {
    margin: 0 0 10px -4px;
  }
`;

const Arrow = styled.div<{ placement?: string }>`
  &,
  &::before {
    width: 10px;
    height: 10px;
    position: absolute;
  }

  &::before {
    background: ${({ theme }) => theme.colors.tooltip.bgColor};
    transform: rotate(45deg);
    content: '';
  }

  ${({ placement }) => (placement === 'top' ? 'bottom: -4px;' : '')}
  ${({ placement }) => (placement === 'bottom' ? 'top: -4px;' : '')}
  ${({ placement }) => (placement === 'left' ? 'right: -4px;' : '')}
  ${({ placement }) => (placement === 'right' ? 'left: -4px;' : '')}
`;

const Cloud = forwardRef<HTMLDivElement, { [key: string]: any }>(
  ({ children, visible, ...rest }, ref: React.Ref<HTMLDivElement>) => {
    if (!visible) return null;
    return (
      <CloudElement ref={ref} {...rest}>
        {children}
      </CloudElement>
    );
  },
);

const Tooltip = ({ id, infoText, displayStatic, cloudOnly, ...rest }: TooltipTypes) => {
  const [tid, setTid] = useState(id);

  const infoRef = useRef<HTMLImageElement>() as React.MutableRefObject<HTMLImageElement>;
  const cloudRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const [arrowRef, setArrowRef] = useState<any>(null);
  const [staticMode, setStaticMode] = useState(displayStatic);
  const { styles, attributes } = usePopper(infoRef.current, cloudRef.current, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
    ],
    placement: 'top',
  });

  const [visible, setVisible] = useState(displayStatic);

  useEffect(() => {
    if (!tid) setTid(v4());
  }, [tid]);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleClose = () => {
    setStaticMode(false);
    setVisible(false);
  };

  const handleOnClick = () => {
    if (staticMode) {
      setStaticMode(false);
      setVisible(false);
    } else if (displayStatic) {
      setStaticMode(true);
      setVisible(true);
    }
  };

  return (
    <Wrapper
      onMouseEnter={staticMode ? () => null : handleMouseEnter}
      onMouseLeave={staticMode ? () => null : handleMouseLeave}
      onClick={handleOnClick}
      {...rest}
      displayStatic={displayStatic}
      onTouchStart={staticMode ? () => null : handleMouseEnter}
      onTouchEnd={staticMode ? () => null : handleMouseLeave}
    >
      {cloudOnly ? (
        ''
      ) : (
        <Info
          data-for={tid}
          src={InfoIcon}
          alt="info icon"
          data-testid="info-button"
          ref={infoRef}
        />
      )}
      <Cloud ref={cloudRef} style={styles.popper} visible={visible} {...attributes.popper}>
        <InnerWrapper>
          <InfoText>{infoText}</InfoText>
          {staticMode ? <Close src={ExitIcon} alt="close button" onClick={handleClose} /> : null}
        </InnerWrapper>
        <Arrow
          placement={attributes?.popper?.['data-popper-placement']}
          ref={setArrowRef}
          style={styles.arrow}
          data-popper-arrow
        />
      </Cloud>
    </Wrapper>
  );
};
export default Tooltip;
