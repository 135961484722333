import { UsersAction } from '../../actions';

type UsersStateReducerType = {
  loading: boolean;
  error: boolean;
  isAuth: boolean;
  data: UsersObject;
  ecyPosition: string;
};

export const initialState: UsersStateReducerType = {
  loading: true,
  error: false,
  isAuth: false,
  ecyPosition: '0',
  data: {
    id: 0,
    email: '',
    walletAddress: '',
    xchWalletAddress: null,
    nickname: null,
    createdAt: '',
    modifiedAt: '',
    roles: [],
  },
};

export const usersReducer = (
  state: UsersStateReducerType = initialState,
  action: UsersAction,
): UsersStateReducerType => {
  switch (action.type) {
    case 'LOGOUT_USER':
      return { ...initialState, loading: false };
    case 'FETCH_USER_DATA_SUCCESS':
      return { ...state, loading: false, isAuth: true, data: action.payload };
    case 'FETCH_USER_DATA_FAILURE':
      return { ...initialState, loading: false, error: true, isAuth: false };
    case 'FETCH_USER_ECY_POSITION_SUCCESS':
      return { ...state, ecyPosition: action.payload };
    default:
      return state;
  }
};
