import { combineReducers } from 'redux';
import { walletReducer } from './walletReducer/walletReducer';
import { alertsReducer } from './alertsReducer/alertsReducer';
import { usersReducer } from './usersReducer/usersReducer';

const rootReducer = combineReducers({
  wallet: walletReducer,
  alerts: alertsReducer,
  users: usersReducer,
});

export default rootReducer;
