import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IModalProps } from './Modal';

export const Container = styled(motion.div)<IModalProps>`
  position: absolute;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  padding: 24px;
  transform: translate(-50%, -50%);
  max-width: ${({ size }) => {
    switch (size) {
      case 'normal':
        return '605px';
      case 'large':
        return '790px';
      case 'small':
        return '390px';
      default:
        return '605px';
    }
  }};
  width: 50%;
  max-height: calc(100vh - 50px);
  border-radius: 3px;
  ${({ theme }) => theme.themeHelpers.breakpoints.down('xxl')} {
    width: 70%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('xl')} {
    width: 70%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('lg')} {
    width: 80%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('md')} {
    width: 90%;
  }
  ${({ theme }) => theme.themeHelpers.breakpoints.down('sm')} {
    width: calc(100% - 24px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  box-shadow: 0 9px 16px rgba(159, 162, 191, 0.18), 0 2px 2px rgba(159, 162, 191, 0.32);
  background: linear-gradient(
    12deg,
    rgba(232, 244, 230, 1) 9%,
    rgba(244, 250, 243, 1) 45%,
    rgba(255, 255, 255, 30) 100%
  );
`;

export const Background = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  background: ${({ theme }) => theme.colors.white50};
  overflow-x: auto;
  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(5px);
    background: ${({ theme }) => theme.colors.white50};
  }
`;

export const Heading = styled.h1`
  margin: 0;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.default};
  padding-right: 24px;
`;

export const ExitIcon = styled.img`
  width: 14px;
`;
export const ExitButton = styled.button`
  position: absolute;
  top: 22px;
  right: 30px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: color 0.2s ease-in-out;
  z-index: ${({ theme }) => theme.zIndex.modal};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: gold;
    transform: scale(1.1);
  }

  i {
    line-height: 24px;
  }
  @media (max-width: 450px) {
    position: absolute;
    top: 7px;
    right: 7px;
  }
`;
