import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Background = styled.div`
  min-height: 100vh;
  background-color: transparent;
  overflow: hidden;
`;

export const Wrapper = styled(motion.div)`
  display: flex;
`;

export const AsideMenu = styled(motion.aside)``;

export const Content = styled(motion.main)<{ isMenuOpen: boolean }>`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 24px;
  max-width: ${({ isMenuOpen }) => (!isMenuOpen ? '100vw' : 'calc(100vw - 354px)')};
  height: calc(100vh - 56px);
  overflow-y: auto;

  @media (min-width: 992px) {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 5px;
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.buttons.grayText};
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.buttons.green};
    }
  }
`;
