import styled from 'styled-components';
import React from 'react';

export interface LabelStakeFundsProps extends React.ComponentPropsWithoutRef<'span'> {
  label: string;
  value: string;
}

const Label = styled.span`
  color: ${({ theme }) => theme.colors.default50};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
`;

const Value = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 4px;
`;

const LabelStakeFunds: React.FC<LabelStakeFundsProps> = ({ label, value, ...rest }) => {
  return (
    <span {...rest}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </span>
  );
};

export default LabelStakeFunds;
