import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AlertsWrapper = styled(motion.div)<{ hasAlerts: boolean }>`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
  pointer-events: none;
  transition: 0.3s;
  z-index: ${({ theme }) => theme.zIndex.modal};

  @media (max-width: 640px) {
    pointer-events: ${({ hasAlerts }) => (hasAlerts ? 'initial' : 'none')};
    justify-content: center;
    background: ${({ theme, hasAlerts }) => (hasAlerts ? theme.colors.white50 : '')};
    @supports (backdrop-filter: blur(10px)) {
      backdrop-filter: ${({ hasAlerts }) => (hasAlerts ? 'blur(10px)' : 'blur(0px)')};
    }
  }
`;

export const Wrapper = styled.div<{ hasAlerts: boolean }>`
  position: absolute;
  top: 18px;
  right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  transition: 0.3s;

  @media (max-width: 640px) {
    justify-content: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;
