import styled from 'styled-components';

export const HamburgerBtn = styled.div<{ size: number }>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size - 2}px`};
  background-color: transparent;
  border: none;
`;

export const Stripe = styled.span`
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.default};
`;

export const TopStripe = styled(Stripe)`
  top: 0;
`;

export const CenterStripe = styled(Stripe)`
  top: calc(50% - 2px);
`;

export const BottomStripe = styled(Stripe)`
  bottom: 0;
`;
