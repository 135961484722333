import { ethers } from 'ethers';
import config, { dev } from '../config';
import { ERC20Abi } from '../abi/erc20.abi';
import { StakingAbi } from '../abi/staking.abi';
import { signer, WalletActionTypes } from '../store/actions';
import { parseEther } from 'ethers/lib/utils';
import { Dispatch } from 'redux';
import { BigNumber } from 'bignumber.js';
import { alertActions } from '../store';

export const enterStaking = async (amount: number | string) => {
  const tokensAmount = parseEther(String(amount));

  const tokenContract = new ethers.Contract(config.TOKEN_ADDRESS, ERC20Abi, signer);
  const enterStakingContract = new ethers.Contract(config.STAKING_ADDRESS, StakingAbi, signer);

  const { wait } = await tokenContract.approve(config.STAKING_ADDRESS, tokensAmount);
  await wait();

  const staking = await enterStakingContract.enterStaking(tokensAmount);
  await staking.wait();

  return staking;
};

export const fetchUserInfoAfterStaking = () => async (dispatch: Dispatch) => {
  try {
    const from = await signer.getAddress();

    const leaveStakingContract = new ethers.Contract(config.STAKING_ADDRESS, StakingAbi, signer);

    const { cooldownPeriodEnd, amount } = await leaveStakingContract.userInfo(from);

    const cooldown = new BigNumber(cooldownPeriodEnd.toString());

    const stakedBalance = new BigNumber(amount.toString())
      .dividedBy(new BigNumber(10).exponentiatedBy(18))
      .toString();

    dispatch({
      type: WalletActionTypes.FETCH_USER_INFO_AFTER_STAKING,
      stakedBalance: stakedBalance,
      cooldown: cooldown,
    });
  } catch (e) {
    dispatch(
      alertActions.error({
        heading: 'Fetching user info!',
        message: 'Error has occurred, please try again later.',
      }),
    );
  }
};

export type FetchUserInfoAfterStakingType = {
  type: WalletActionTypes.FETCH_USER_INFO_AFTER_STAKING;
  cooldown: string;
  stakedBalance: string;
};

export const unStaking = async (amount: number | string) => {
  const tokensAmount = parseEther(String(amount));

  const leaveStakingContract = new ethers.Contract(config.STAKING_ADDRESS, StakingAbi, signer);

  const leaveStaking = await leaveStakingContract.leaveStaking(tokensAmount);

  await leaveStaking.wait();

  return leaveStaking;
};

export const fetchUserPositionECY = async (walletAddress: string) => {
  const localProvider = new ethers.providers.JsonRpcProvider(
    dev() ? 'https://data-seed-prebsc-1-s1.binance.org:8545/' : 'https://bsc-dataseed1.defibit.io/',
  );
  const contract = new ethers.Contract(config.STAKING_ADDRESS, StakingAbi, localProvider);
  const userInfo = await contract.functions.userInfo(walletAddress);
  const position = new BigNumber(userInfo.amount._hex)
    .dividedBy(new BigNumber(10).exponentiatedBy(18))
    .toString();
  return position;
};
